/****************************************************************************************
 * File Name    : Products.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <div v-if = "flagForSpinner==false">
            <a-row :gutter="16">
                <a-col class="gutter-row" :span="12">
                    <div class="gutter-box">
                        <PageHeader title = "제품 등록 " />
                    </div>
                </a-col>
            </a-row>
            <a-row type="flex" justify="center" align="top" :gutter="8">
                <a-col :span="12">
                    <a-card hoverable style="height: 50%" @click="showDrawer">
                        <a-card-meta title="" class = 'card_action'>
                            <template slot="description" >
                                <a-button type="primary" icon="plus-circle" size = "default" @click="productType = 'WATER';" >
                                    정수기 등록
                                </a-button>
                            </template>
                        </a-card-meta>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card hoverable style="height: 50%" @click="showDrawer">
                        <a-card-meta title="" class = 'card_action'>
                            <template slot="description" >
                                <a-button type="primary" icon="plus-circle" size = "default" @click="productType = 'AIR';" >
                                    청정기 등록
                                </a-button>
                            </template>
                        </a-card-meta>
                    </a-card>
                </a-col>
            </a-row>
            <p/>
            <a-row type="flex" justify="center" align="top" :gutter="8">
                <a-col :span="12">
                    <a-input-search placeholder="검색 제품명 / 모델명" style="width: 100%" v-model="filterWater" v-on:keyup="filterProductWater" />
                    <p/>
                    <a-table :columns="columns1" size = "small" :bordered=false :data-source="data1" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                        <span slot="action" slot-scope="record">
                            <span class = 'icon_button' @click="editProduct(record.productSeq)"><a-icon type="edit" /> </span>
                            <a-divider type = 'vertical' />
                            <a-icon type="delete" @click="deleteProduct(record.productSeq)" style="cursor:pointer;" />
                        </span>
                        <span slot="tags" slot-scope="tags">
                            <a-tag v-for="(item,index) in tags" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >
                                {{ item.toUpperCase() }}
                            </a-tag>
                        </span>
                        <span slot="image" slot-scope="image" style="display:inline-block; height:35px;">
                            <img :src = "image" style="height:35px;"/>
                        </span>
                        <span slot="name" slot-scope="text">{{ text }}</span>
                    </a-table>
                </a-col>
                <a-col :span="12">
                    <a-input-search placeholder="검색 제품명 / 모델명" style="width: 100%" v-model="filterAir" v-on:keyup="filterProductAir" />
                    <p/>
                    <a-table :columns="columns2" size = "small" :bordered=false :data-source="data2" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                        <span slot="action" slot-scope="record">
                            <span class = 'icon_button' @click="editProduct(record.productSeq)"><a-icon type="edit" /> </span>
                            <a-divider type = 'vertical' />
                            <a-icon type="delete" @click="deleteProduct(record.productSeq)" style="cursor:pointer;" />
                        </span>
                        <span slot="tags" slot-scope="tags">
                            <a-tag v-for="(item,index) in tags" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >
                                {{ item.toUpperCase() }}
                            </a-tag>
                        </span>
                        <span slot="image" slot-scope="image" style="display:inline-block; height:35px;">
                            <img :src = "image" style="height:35px;"/>
                        </span>
                        <span slot="name" slot-scope="text">{{ text }}</span>
                    </a-table>

                    <a-row type="flex" justify="center" align="top" :gutter="8" style="margin-top:30px;">
                        <a-col :span="24">
                            <a-card hoverable style="height: 50%" @click="showDrawer">
                                <a-card-meta title="" class = 'card_action'>
                                    <template slot="description" >
                                        <a-button type="primary" icon="plus-circle" size = "default" @click="productType = 'ETC';" >
                                            기타기기 등록
                                        </a-button>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                        <a-col :span="24" style="margin-top:15px;">
                            <a-input-search placeholder="검색 제품명 / 모델명" style="width: 100%" v-model="filterEtc" v-on:keyup="filterProductEtc" />
                            <p/>
                            <a-table :columns="columns3" size = "small" :bordered=false :data-source="data3" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                                <span slot="action" slot-scope="record">
                                    <span class = 'icon_button' @click="editProduct(record.productSeq)"><a-icon type="edit" /> </span>
                                    <a-divider type = 'vertical' />
                                    <a-icon type="delete" @click="deleteProduct(record.productSeq)" style="cursor:pointer;" />
                                </span>
                                <span slot="tags" slot-scope="tags">
                                    <a-tag v-for="(item,index) in tags" :key='index' :color="index===0 ? '#7F7F7F' : '#5B5B5B'" >
                                        {{ item.toUpperCase() }}
                                    </a-tag>
                                </span>
                                <span slot="image" slot-scope="image" style="display:inline-block; height:35px;">
                                    <img :src = "image" style="height:35px;"/>
                                </span>
                                <span slot="name" slot-scope="text">{{ text }}</span>
                            </a-table>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </div>
        <a-skeleton v-else />
        <!-- DESC : 제품등록폼 -->
        <a-drawer title="제품등록/수정/삭제" placement="right" width="70vw" :closable="false" :visible="flagForDrawer" :get-container="false" @close="closeDrawer" >
            <ProductForm :type = "productType" :productSeq="selectedProduct.productSeq" :productName="selectedProduct.name" :productModel="selectedProduct.model" :productModelDetail="selectedProduct.modelDetail" :productPop="selectedProduct.pop" :productSap="selectedProduct.sap" :image="selectedProduct.image" :uploadFileSeq="selectedProduct.uploadFileSeq" :waterList="data1" :airList="data2" :etcList="data3" v-on:addProduct="addProduct"/>
        </a-drawer>
    </div>
</template>
<script>
// TODO : DB작업후 실제 자료로 변경해야함.
// TODO : 제품 테이블을 공통 모듈로 수정해야 한다.
const columns1 = [
    { title: '이미지',dataIndex: 'image',key: 'image',  scopedSlots: { customRender: 'image'},  width : 150},
    { title: '모델명',dataIndex: 'model',key: 'model',  scopedSlots: { customRender: 'model'},},
    { title: '세부모델명',dataIndex: 'modelDetail',key: 'modelDetail',  scopedSlots: { customRender: 'modelDetail'},},
    { title: '제품명',dataIndex: 'name',key: 'name',  scopedSlots: { customRender: 'name'},},
    { title: '관리',key: 'action',scopedSlots: { customRender: 'action'},  width : 100},
];
const columns2 = [
    { title: '이미지',dataIndex: 'image',key: 'image',  scopedSlots: { customRender: 'image'},  width : 150},
    { title: '모델명',dataIndex: 'model',key: 'model',  scopedSlots: { customRender: 'model'},},
    { title: '세부모델명',dataIndex: 'modelDetail',key: 'modelDetail',  scopedSlots: { customRender: 'modelDetail'},},
    { title: '제품명',dataIndex: 'name',key: 'name',  scopedSlots: { customRender: 'name'},},
    { title: '관리',key: 'action',scopedSlots: { customRender: 'action'},  width : 100},
];
const columns3 = [
    { title: '이미지',dataIndex: 'image',key: 'image',  scopedSlots: { customRender: 'image'},  width : 150},
    { title: '모델명',dataIndex: 'model',key: 'model',  scopedSlots: { customRender: 'model'},},
    { title: '세부모델명',dataIndex: 'modelDetail',key: 'modelDetail',  scopedSlots: { customRender: 'modelDetail'},},
    { title: '제품명',dataIndex: 'name',key: 'name',  scopedSlots: { customRender: 'name'},},
    { title: '관리',key: 'action',scopedSlots: { customRender: 'action'},  width : 100},
];

import _ from "lodash";
import PageHeader from "../components/PageHeader.vue"     ;
import ProductForm from "../components/ProductForm.vue"    ;
export default {
    name: 'Products',
    components: {
        PageHeader  ,
        ProductForm ,
    },
    data() {
        return {
            flagForSpinner : false,
            flagForDrawer: false,
            data1: [],
            data2: [],
            data3: [],
            data1Backup: [],
            data2Backup: [],
            data3Backup: [],
            columns1,
            columns2,
            columns3,
            productType: '',
            selectedProduct: {},
            filterWater: '',
            filterAir: '',
            filterEtc: ''
        };
    },
    mounted() {

        this.loadProductList();
    },
    methods : {
        addProduct() {
            setTimeout(() => {
                location.reload();
            }, 1000);

            // this.flagForDrawer = false;
            // this.data1 = [];
            // this.data2 = [];
            // this.loadProductList();
        },
        // DESC : 등록된 제품리스트를 조회한다.
        loadProductList() {
            this.flagForSpinner = true;
            this.data1 = [];
            this.data2 = [];
            this.data3 = [];
            let urlToFetchProducts = "https://coway.linkit.me:8100/product/list?size=10000&page=0";
            this.$http.get(urlToFetchProducts)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    let dataArray = response.data.data.content;
                    dataArray = dataArray.reverse();    // 최종생성된 것이 위로 가도록 정렬
                    for(let x = 0; x < dataArray.length; x ++ ) {
                        let type;

                        let temp = _.find(dataArray[x].listProductType, {
                            productTypeName: 'WATER'
                        });
                        if (temp) {
                            type = 'WATER';
                        } else {
                            let temp2 = _.find(dataArray[x].listProductType, {
                                productTypeName: 'AIR'
                            });
                            if (temp2) {
                                type = 'AIR';
                            } else {
                                let temp3 = _.find(dataArray[x].listProductType, {
                                    productTypeName: 'ETC'
                                });
                                if (temp3) {
                                    type = 'ETC';
                                }
                            }
                        }

                        let element = {
                            key : x,
                            productSeq : dataArray[x].productSeq,
                            model : dataArray[x].productModel,
                            modelDetail : dataArray[x].productModelDetail,
                            name : dataArray[x].productName,
                            pop  : dataArray[x].productPop,
                            sap  : dataArray[x].productSap,
                            tags : [dataArray[x].productPop,dataArray[x].productSap],
                            uploadFileSeq : dataArray[x].uploadFileSeq,
                            type : type,
                            selected : false,
                            image : this.$store.state.hostInService + '/upload/image/download/' + dataArray[x].uploadFileSeq
                        }

                        if (type === 'WATER') {
                            this.data1.push(element);
                        } else if (type === 'AIR') {
                            this.data2.push(element);
                        } else if (type === 'ETC') {
                            this.data3.push(element);
                        }
                    }

                    this.data1Backup = JSON.parse(JSON.stringify(this.data1));
                    this.data2Backup = JSON.parse(JSON.stringify(this.data2));
                    this.data3Backup = JSON.parse(JSON.stringify(this.data3));
                }
                this.flagForSpinner = false;

            }).catch(error => {
                console.log(error);
                this.flagForSpinner = false;
            });
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        showDrawer() {
            this.selectedProduct = {};
            this.flagForDrawer = true;
        },
        closeDrawer() {
            this.flagForDrawer = false;
        },
        editProduct(productSeq) {
            let obj = _.find(this.data1, {
                productSeq: productSeq
            });
            if (!obj) {
                obj = _.find(this.data2, {
                    productSeq: productSeq
                });
                if (!obj) {
                    obj = _.find(this.data3, {
                        productSeq: productSeq
                    });
                }
            }
            this.selectedProduct = obj;console.log(this.selectedProduct);
            this.productType = this.selectedProduct.type;
            this.flagForDrawer = true;
        },
        deleteProduct(productSeq) {
            if (confirm('삭제하시겠습니까?')) {
                let urlForProduct = this.$store.state.hostInService + '/product/delete';
                let generatedObjectToPersist = {
                    productSeq: productSeq
                };
                this.$http.put(urlForProduct,generatedObjectToPersist)
                    .then((response) => {
                        if (response.status === 200 && response.data.code === 'success') {
                            this.$message.success('삭제되었습니다');
                            this.loadProductList();
                        }  else {
                            this.$message.error('삭제되지 않았습니다(1).');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('삭제되지 않았습니다(2).');
                    });
            }
        },
        filterProductWater() {
            if (this.filterWater) {
                let re = new RegExp(this.filterWater, 'gi');

                this.data1 = _.filter(this.data1Backup, (item) => {
                    return item.name && item.name.match(re) || item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                });
            } else {
                this.data1 = this.data1Backup;
            }
        },
        filterProductAir() {
            if (this.filterAir) {
                let re = new RegExp(this.filterAir, 'gi');

                this.data2 = _.filter(this.data2Backup, (item) => {
                    return item.name && item.name.match(re) || item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                });
            } else {
                this.data2 = this.data2Backup;
            }
        },
        filterProductEtc() {
            if (this.filterEtc) {
                let re = new RegExp(this.filterEtc, 'gi');

                this.data2 = _.filter(this.data2Backup, (item) => {
                    return item.name && item.name.match(re) || item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                });
            } else {
                this.data2 = this.data2Backup;
            }
        }
    }


// Quatro Air Purifier AP-3018-B
// 노블 공기청정기 (67 ㎡) AP-2021A
// 코웨이 AIS (아이스) 3.0 CHPI-7511L
// Edit Delete
// Nano Direct
// Water Purifier
// Edit Delete
// CHP-7200N
// Water Purifier CP-08DL/R
// Edit Delete
// POP#, SAP#
// POP#, SAP#
// POP#, SAP#
// Edit Delete
// Edit Delete
// POP#, SAP
}
</script>
<style scoped>
    .div_action {
        width : 100%;
        text-align: right;
        padding-bottom: 10px;
        margin-top:15px;
    }
    .card_action {
        text-align:right;
    }
</style>