/****************************************************************************************
 * File Name    : Factory.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
        <div class = 'div_factory'>
            <!--  -->
            <a-form :form="form" @submit="onSubmit">
                <a-icon type="info-circle" /> 표시된 항목은 필수입력입니다.
                <p/>
                <a-card>
                    <a-form-item label="Plant Name">
                        <a-input placeholder="Ugu Plant" v-decorator="['plantName',{rules: [{ required: true, message: 'Plant Name is required!' }],},] "/>
                    </a-form-item>
                    <a-form-item label="Address">
                        <a-input placeholder="658 Yugu-ri, Yugu-eup, Gongju-si, " v-decorator="['Address',{rules: [{ required: true, message: 'Address is required!' }],},]"/>
                    </a-form-item>
                    <a-form-item>
                        <a-input placeholder="Chungcheongnam-do " v-decorator="['AddressDetail',{rules: [{ required: true, message: 'Address Detail is required!' }],},]"/>
                    </a-form-item>
                    <a-form-item label="" style="margin-bottom:0;">
                        <a-form-item placeholder="Please select the correct date" :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }" >
                            <a-input placeholder="Republic of Korea (Country)" v-decorator="['Country',{rules: [{ required: true, message: 'Country is required!' }],},]"/>
                        </a-form-item>
                        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
                        <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                            <a-input placeholder="314895 (Zip Code)" v-decorator="['Zip Code',{rules: [{ required: true, message: 'Zip Code is required!' }],},]"/>
                        </a-form-item>
                    </a-form-item>
                    <a-form-item label="Email">
                        <a-input placeholder="Mater Id Owner’s email in Ugu Factory" v-decorator="['Email',{rules: [{ required: true, message: 'Email is required!' }],},]"/>
                    </a-form-item>
                    <a-form-item label="TIme Zone">
                        <a-select v-decorator="['TIme Zone',{ rules: [{ required: true, message: 'Please select TIme Zone!' }] },]" placeholder="Select a option and change input text above" >
                            <a-select-option value="male">Asia/Seou</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="Language">
                        <a-radio-group  v-decorator="['Language',{rules: [{ required: true, message: 'Please select Language!' }],},]" style = 'width:100%'>
                            <a-radio-button value="Korean">Korean</a-radio-button>
                            <a-radio-button value="English">English</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="Currency">
                        <a-radio-group  v-decorator="['Currency',{rules: [{ required: true, message: 'Please select Currency!' }],},]" style = 'width:100%'>
                            <a-radio-button value="₩">Korean Won ₩</a-radio-button>
                            <a-radio-button value="$">USD $</a-radio-button>
                            <a-radio-button value="¥">Chinese Yuan ¥</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="Temperature">
                        <a-radio-group  v-decorator="['Temperature',{rules: [{ required: true, message: 'Please select Temperature!' }],},]" style = 'width:100%'>
                            <a-radio-button value="C">Celsius(˚C)</a-radio-button>
                            <a-radio-button value="F">Fahrenheit (F)</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <!--
                    <a-form-item label="Currency">
                        <a-input v-decorator="['Currency',{rules: [{ required: true, message: 'Please input Currency!' }],},]" style="width: 100%">
                            <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: 'Korean Won ₩' }]" style="width: 200px" >
                                <a-select-option value="₩"> Korean Won ₩</a-select-option>
                                <a-select-option value="$"> USD $ </a-select-option>
                                <a-select-option value="¥"> Chinese Yuan ¥</a-select-option>
                            </a-select>
                        </a-input>
                    </a-form-item>
                    <a-form-item label="Temperature">
                        <a-input v-decorator="['Temperature',{rules: [{ required: true, message: 'Please input Temperature!' }],},]" style="width: 100%">
                            <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: 'Celsius(˚C)' }]" style="width: 200px" >
                                <a-select-option value="C"> Celsius(˚C) </a-select-option>
                                <a-select-option value="F"> Fahrenheit (F) </a-select-option>
                            </a-select>
                        </a-input>
                    </a-form-item>
                    -->
                </a-card>
                <!--  -->
                <p/>
                <a-button type="primary" html-type="submit" :ghost = true>
                    Save
                </a-button>
            </a-form>

  <!--  -->
        </div>
</template>
<script>
export default {
    name: 'Factory',
    components: {
    },
    data() {
        return {
            isDataLoaded : false,
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 5 },
            // },
            // wrapperCol: {
            //     xs: { span: 24 },
            //     sm: { span: 12 },
            // },
        };
    },
    mounted() {
        this.isDataLoaded = true;
    },
    created() {
        this.form = this.$form.createForm(this, {
                onFieldsChange: (_, changedFields) => {
                    this.$emit('change', changedFields);
                },
                mapPropsToFields: () => {
                    return {
                        username: this.$form.createFormField({
                            value: this.username,
                        }),
                    };
                },
                onValuesChange: (_, values) => {
                    console.log(values);
                },
        });
    },
    methods : {

        onSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                console.log('Received values of form: ', values);
                // this.$store.commit('update', values);
                }
            });
        },
    }
}
</script>
<style scoped>
    .div_factory {
        text-align : left
    }
    #components-form-demo-vuex .language-bash {
        max-width: 400px;
        border-radius: 6px;
        margin-top: 24px;
    }
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }

</style>