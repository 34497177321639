/****************************************************************************************
 * File Name    : App.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.06.26  	최명호    	Create
****************************************************************************************/
<template>

    <div id="app">
        <!--  -->
        <a-layout id="components-layout-top-side">
            <a-layout-header v-if="$route.fullPath !== '/'" class="header" style="background-color:#fff !important;">
                <a-row :gutter="16">
                    <a-col :span="4" style="line-height:50px;">
                        <div class="logo" >         
                        </div>
                        <span style="float:left; margin-right:15px; color:#0099DC; font-weight:bold; font-size:20px;">Coway</span>
                        <span class="site-name top-header-title" v-if="headerTitles[headerSelectedIndex] === 'Home'" style="margin-top:2px; font-weight:bold; font-size:22px; color:#111F48;">Dashboard</span>
                        <span class="site-name top-header-title" v-if="headerTitles[headerSelectedIndex] !== 'Home'" v-text="headerTitles[headerSelectedIndex]" style="margin-top:2px; font-weight:bold; font-size:22px; color:#111F48;"></span>
                    </a-col>
                    <a-col :span="20">
                        <a-row :gutter="16">
                            <a-col :span="6">

                            </a-col>
                            <a-col :span="18">
                                <a-menu theme="light" mode="horizontal" :default-selected-keys="[0]" :style="{ lineHeight: '50px', textAlign: 'right', marginRight: '200px', fontWeight: 'bold', fontSize: '16px', border: 'none' }" @click="headerMenuClick">
                                    <!-- Home 메뉴 제외한 나머지 메뉴 출력 -->
                                    <a-menu-item v-for="(headerTitle,headerIndex) in headerTitles" :key='headerIndex' v-bind:style= "headerIndex === 5 ? 'margin-left:35%' : ''">
                                        <!-- <img v-if="headerIndex === 0" src="./assets/top-menu-icon0.png" class="top-menu-icon">
                                        <img v-if="headerIndex === 1" src="./assets/top-menu-icon1.png" class="top-menu-icon">
                                        <img v-if="headerIndex === 2" src="./assets/top-menu-icon2.png" class="top-menu-icon">
                                        <img v-if="headerIndex === 3" src="./assets/top-menu-icon3.png" class="top-menu-icon">
                                        <img v-if="headerIndex === 4" src="./assets/top-menu-icon4.png" class="top-menu-icon">
                                        <img v-if="headerIndex === 5" src="./assets/top-menu-icon5.png" class="top-menu-icon"> -->
                                        <span class="title">{{headerTitle}}</span>
                                    </a-menu-item>
                                </a-menu>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-layout-header>

            <a-layout-content style="position:relative; padding: 0px 50px">
                <!-- <a-breadcrumb style="margin: 0px 0;text-align:left">
                    <a-breadcrumb-item>Home</a-breadcrumb-item>
                    <a-breadcrumb-item>List</a-breadcrumb-item>
                    <a-breadcrumb-item>App</a-breadcrumb-item>
                </a-breadcrumb> -->
                <a-button v-if="$route.fullPath !== '/'" style="position:absolute; top:-40px; right:65px; border:none;" @click="logout()" type="default" html-type="button" :ghost = false size = "small"><img src="./assets/images/btn-logout.png" /></a-button>
                <a-divider type = 'horizontal' />
                <a-layout style="padding: 0px 0; background: #fff">
                    <a-layout-sider v-if="!$route.fullPath.match('dashboard')" width="250" style="background: #fff">
                        <!-- <router-link to="/">COWAY</router-link> -->
                        <a-menu mode="inline" :default-selected-keys="[headerSelectedIndex + ',' + subSelected[headerSelectedIndex]]" :default-open-keys="[]" style="height: 100%" :key="headerSelectedIndex">
                        <!--a-menu mode="inline"  :default-open-keys="[]" style="height: 100%" -->

                            <a-menu-item v-for="(title,index) in titles[headerSelectedIndex]" :key= "+headerSelectedIndex + ',' + index" class="desktop-only" @click="menuClick" >
                                <router-link :to="routers[headerSelectedIndex][index]"            >
                                    <a-icon :type='icons[headerSelectedIndex][index]'></a-icon>
                                    {{ title }}                
                                </router-link>
                            </a-menu-item>


                            <!--
                            <a-menu-item key="Factory" class="desktop-only" v-if = "headerSelected !== 'Home'"><router-link to="/factory"            >  <a-icon type="bank"            />유구공장                </router-link></a-menu-item>
                            <a-menu-item key="Accounts" class="desktop-only"><router-link to="/account"            >  <a-icon type="usergroup-add"   />사용자 계정 관리        </router-link></a-menu-item>
                            <a-menu-item key="Products" class="desktop-only"><router-link to="/product"            >  <a-icon type="plus-circle"     />제품 등록               </router-link></a-menu-item>
                            <a-menu-item key="Finished" class="desktop-only"><router-link to="/finished"           >  <a-icon type="exception"       />초/중/종품 검사 성적서 등록     </router-link></a-menu-item>
                            <a-menu-item key="Patrol" class="desktop-only"><router-link to="/patrol"             >  <a-icon type="file-done"       />공정 순회검사 체크시트 등록  </router-link></a-menu-item>
                            <a-menu-item key="Equipments" class="desktop-only"><router-link to="/equipment"          >  <a-icon type="plus-circle"     />설비 점검 검사               </router-link></a-menu-item>
                            <a-menu-item key="Tools" class="desktop-only"><router-link to="/tool"               >  <a-icon type="plus-circle"     />치공구 계측기 관리      </router-link></a-menu-item>
                            <a-menu-item key="Data" class="desktop-only"><router-link to="/data"               >  <a-icon type="area-chart"      />Data 보기               </router-link></a-menu-item>
                            <a-menu-item key="Report" class="desktop-only"><router-link to="/report"             >  <a-icon type="setting"         />보고서 관리             </router-link></a-menu-item>
                            <a-menu-item key="Dashboard" class="desktop-only"><router-link to="/dashboard"          >  <a-icon type="dashboard"       />대시보드 관리           </router-link></a-menu-item>
                            <a-divider type = "horizontal" class="desktop-only" />
                            <a-menu-item key="SheetFinished" class="desktop-only"><router-link to="/sheetFinished"      >  <a-icon type="profile"         />초/중/종품 검사 현황             </router-link></a-menu-item>
                            <a-menu-item key="SheetPatrol" class="desktop-only"><router-link to="/sheetPatrol"      >  <a-icon type="profile"       />공정순회 검사 현황          </router-link></a-menu-item>
                            <a-menu-item key="SheetEquipmentControls" class="desktop-only"><router-link to="/sheetEquipment"     >  <a-icon type="profile"         />설비점검시트 작성               </router-link></a-menu-item>
                            <a-menu-item key="SheetToolControls" class="desktop-only"><router-link to="/sheetTool"          >  <a-icon type="profile"         />치공구/계측기 관리 시트 작성    </router-link></a-menu-item>
                            <a-menu-item key="CSVersion" class="desktop-only"><router-link to="/csVersion"          >  <a-icon type="profile"         />CS Version 관리                 </router-link></a-menu-item>
                            -->
                            <!-- <a-menu-item key="EquipmentControls" class="desktop-only"><router-link to="/equipmentControl"   >  <a-icon type="monitor"         />설비 점검 검사          </router-link></a-menu-item> -->
                            <!-- <a-menu-item key="ToolControls" class="desktop-only"><router-link to="/toolControl"        >  <a-icon type="read"            />치공구 계측기 관리일지  </router-link></a-menu-item> -->
                        </a-menu>
                    </a-layout-sider>
                    <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                        <router-view :key="$route.fullPath" />
                    </a-layout-content>
                </a-layout>
            </a-layout-content>
        </a-layout>
  </div>
</template>
<script>
import _ from "lodash";

export default {
    name: 'App',
    components: {
    },
    computed: {
        currentRouteName() {
            let routeName = this.$route.name
            if (routeName === 'Home') { // 로그인시
                routeName = 'Dashboard';
            }

            return routeName;
        },
        currentRoutePath() {
            return this.$route.path;
        }
    },
    data() {
        return {
            headerTitles: ['Home','초/중/종품','공정순회','설비','치공구외','관리자','사용자이름'],
            routers:[['/dashboard'],['/finished','/sheetFinished','/finishedReport'],['/patrol','/sheetPatrol','/patrolReport'],['/equipment','/sheetEquipment','/equipmentReport'],['/tool','/toolReport'],['/factory','/account','/product']],
            titles:[['대시보드 관리'],['검사 성적서 등록','검사 현황','보고서 관리'],['체크시트 등록','검사 현황','보고서 관리'],['체크시트 등록','검사 현황','보고서 관리'],['검사 현황','보고서 관리'],['유구공장','사용자 계정 관리','제품 등록']],
            icons:[['dashboard'],['exception','profile','setting'],['exception','profile','setting','profile'],['exception','profile','setting'],['profile','setting'],['bank','usergroup-add','plus-circle']],
            headerSelectedIndex: 0,
            subSelected:[0,0,0,0,0,0],

            userName: '',
            userNumber: '',
            userKind: '',
        };
    },
    mounted() {
        // console.log(this.currentRouteName)
        // this.$router.push(this.routers[0][0])
        this.menuHighlight(this.currentRoutePath);
        this.handleRoute();
    },
    methods : {
        headerMenuClick(e) {
            console.log(e);
            // 상단 메뉴 클릭시 이전에 클릭했던 소메뉴로 이동
            /*
            // if(this.headerSelectedIndex != e.key){
                this.$router.push(this.routers[e.key][this.subSelected[e.key]])
            // }
            this.headerSelectedIndex = e.key;
            //this.$router.push('/product');
            */

            // 상단 메뉴 클릭시 메뉴의 첫번째 소메뉴로 이동
            this.$router.push(this.routers[e.key][this.subSelected[0]])
            // this.headerSelectedIndex = 0;
        },
        menuClick(e){
            console.log('menuClick:',e);
            const list = e.key.split(',');
            console.log('list[1]:',list[1]);
            
            this.subSelected[this.headerSelectedIndex] = list[1];

        },
        test() {
            this.$store.commit('setFlagForDemo',false);
        },
        logout() {
            sessionStorage.removeItem('user_number');
            sessionStorage.removeItem('user_name');
            sessionStorage.removeItem('user_kind');

            this.$router.push('/');

            //'' + headerSelectedIndex + ',' + subSelected[headerSelectedIndex]
        },
        menuHighlight(toPath) {   // 상단, 좌측 메뉴 selected 적용
            let self = this;
            
            let routers = [['/dashboard'],['/finished','/sheetFinished','/finishedReport'],['/patrol','/sheetPatrol','/patrolReport','/patrol4m'],['/equipment','/sheetEquipment','/equipmentReport'],['/tool','/toolReport'],['/factory','/account','/product']];
            let headerMenuIndex = 0;
            let leftMenuIndex = 0;
    
            // routers 에 없는 메뉴에 대한 처리
            if (toPath === '/finishedProduct') {
                toPath = '/finished';
            } else if (toPath === '/patrolProduct') {
                toPath = '/patrol';
            }

            // 상단 메뉴 적용
            let headerMenu = self.$el.querySelector('.ant-menu-horizontal') && self.$el.querySelector('.ant-menu-horizontal').querySelectorAll('.ant-menu-item');
            if (headerMenu) {
                headerMenu.forEach((item) => {
                    item.classList.remove('ant-menu-item-selected');
                });

                _.forEach(routers, (item, i) => {
                    if (item.indexOf(toPath) > -1) {
                        headerMenuIndex = i;
                    }
                });
                headerMenu[headerMenuIndex].classList.add('ant-menu-item-selected');

                self.headerSelectedIndex = headerMenuIndex;
            }

            // 좌측 메뉴 적용
            setTimeout(function() {
                let leftMenu = self.$el.querySelector('.ant-menu-inline') && self.$el.querySelector('.ant-menu-inline').querySelectorAll('.ant-menu-item');
                if (leftMenu) {
                    leftMenu.forEach((item) => {
                        item.classList.remove('ant-menu-item-selected');
                    });

                    _.forEach(routers[headerMenuIndex], (item, i) => {
                        if (item === toPath) {
                            leftMenuIndex = i;
                        }
                    });
                    leftMenu[leftMenuIndex].classList.add('ant-menu-item-selected');
                }

                let topHeaderTitle = self.$el.querySelector('.top-header-title');
                if (topHeaderTitle) {
                    topHeaderTitle.innerHTML = self.headerTitles[headerMenuIndex];
                }
            }, 0);
        },
        handleRoute(to, from) {
            this.userName = sessionStorage.getItem('user_name');
            this.userNumber = sessionStorage.getItem('user_number');
            this.userKind = sessionStorage.getItem('user_kind');

            if (to && from) {
                console.log(to);
                console.log(from);

                // 로그인 안된 상태
                if (to.path !== '/' && !this.userNumber) {
                    this.$router.push('/');
                }

                // 로그인 상태
                if (this.userName) {
                    // 상단 메뉴 제일 우측 관리자를 로그인 사용자 이름으로 변경
                    setTimeout(() => {
                        this.$el.querySelectorAll('.ant-menu.ant-menu-horizontal.ant-menu-root li')[13].querySelector('.title').innerHTML = this.userName + '(' + this.userKind + ')';
                    }, 200);
                }

                // 상단, 좌측 메뉴 selected 적용
                this.menuHighlight(to.path);
            }
            // 최초 접속 또는 새로고침 하는 경우
            else {
                // 로그인 안된 상태
                if (!this.userNumber) {
                    this.$router.push('/');
                }

                // 로그인 상태
                if (this.userName) {
                    // 상단 메뉴 제일 우측 관리자를 로그인 사용자 이름으로 변경
                    setTimeout(() => {
                        this.$el.querySelectorAll('.ant-menu.ant-menu-horizontal.ant-menu-root li')[13].querySelector('.title').innerHTML = this.userName + '(' + this.userKind + ')';
                    }, 200);
                }
            }
        }
    },
    watch:{
        $route (to, from){
            this.handleRoute(to, from);
        }
    }
}
</script>
<style>
    #app {
    font-family : Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing : grayscale;
    text-align : left;
    color : #2c3e50;
    }
    .ant-layout-header {
        height: 50px !important;
        padding: 0 50px;
        line-height: 40px;
        background: #FFF;
    }
    .ant-layout {
        background: #FFF !important;
    }
    .ant-layout-header {
        background: #FFF !important;
    }
    .ant-divider-horizontal {
        margin: 10px 0 !important;
    }
    .header {
        /* font-size : 20px; */
    }
    .icon_button {
        cursor : pointer;
    }
    .div_action {
        width : 100%;
        text-align: right;
        padding-bottom: 10px;
        /* margin-top:15px; */
    }
    .ant-form-item  {
        margin-bottom: 5px !important;
    }
    .ant-form-item-label  {
        font-weight: 700  !important;
    }
    .todo {
        display: none;
        color:#9B0000;
        font-weight : 700;
        margin : 0px 0px 10px 0px;
        text-decoration: underline;
    }

    /* 바코드 스캐너 */
    #qr-reader__dashboard_section_swaplink {
        display: none !important;
    }

    /* 태블릿 */
    /* @media (max-width: 1280px) {
        .ant-layout-header,
        .ant-breadcrumb,
        .ant-divider-horizontal,
        .desktop-only,
        .ant-layout-sider {
            display: none !important;
        }
    } */

    #components-layout-top-side
    .logo{
        display: none;
        width: 30px;
        height: 31px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px 28px 16px 0;
        float: left;

    }
    .site-name{
        height: 31px;
        margin: 16px 28px 16px 0;
        float: left;
    }

    .top-menu-icon {
        width: 30px;
    }

    .ant-menu-root .title {
        margin-left: 5px;
    }
</style>
