/****************************************************************************************
 * File Name    : CSVersion.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-card title = "검사항목 01.포장박스">
            <a slot="extra" href="#">변경</a>
            <h4>1-1-1. 세부검사기준</h4>
            <p/>
            <h4>1-1-2. 참고이미지</h4>
            <p/>
            <h4>1-1-3. 검사항목 설명</h4>
            <a-divider type = 'horizontal' />
            <h4>1-2-1. 세부검사기준</h4>
            <p/>
            <h4>1-2-2. 참고이미지</h4>
            <p/>
            <h4>1-2-3 검사항목 설정</h4>
        </a-card>

    </div>
</template>
<script>

export default {
    name: 'CSVersion',
    components: {

    },
    data() {
        return {
            isDataLoaded : false,
        };
    },
    mounted() {
        this.isDataLoaded = true;
    },
    methods : {

    }
}
</script>
<style scoped>
</style>