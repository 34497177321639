/****************************************************************************************
 * File Name    : SheetFinished.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <div id="qr-reader" style="margin:0 auto; width:600px; height:300px;">
            <span id='lib-load' style='font-size:x-large' hidden>Loading Library...</span><br />
            <button id="showScanner" hidden style="display:none;">Show The Scanner</button>
        </div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "초/중/종품 검사 성적서" />
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                    <a-input v-model="barcode" placeholder="바코드&제품코드" style="margin-right: 10px; width: 200px"/>
                    <span class = 'icon_button' @click="handlerToCreateCSForProudctBarcode()">
                        <a-tag color="#2980B9" style="cursor:pointer;">
                            <a-icon type="plus-circle" /> 불러오기
                        </a-tag>
                    </span>
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <a-space align="end">
            <!-- <a-statistic title="작성필요" :value="data.length" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="check-circle" />
                </template>
            </a-statistic> -->
            <a-statistic title="검사중" :value="data.length" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="question-circle" />
                </template>
            </a-statistic>
             <a-statistic title="검사완료" :value="data3.length" style="margin-right: 50px">
                <template #prefix>
                    <a-icon type="exclamation-circle" />
                </template>
            </a-statistic>
            <a-radio-group style = ''>
                <!-- <a-radio-button value="작성필요">작성필요 보기</a-radio-button> -->
                <!-- <a-radio-button value="검사중">검사중 보기</a-radio-button>
                <a-radio-button value="검사완료">검사완료 보기</a-radio-button>
                <a-radio-button value="모두">모두보기</a-radio-button> -->
            </a-radio-group>
        </a-space>
        <p/>
        <a-progress type = 'line' :percent="90" strokeColor = '#C7C6C6'/>
        <p/>
        <a-table :columns="columns" size = "small" :bordered=false :data-source="data" :pagination="true" :scroll="{ x: 0, y: '30vh' }">
            <span slot="tags" slot-scope="tags">
                <a-tag v-for="tag in tags" :key="tag" class="state-btn" :class="{ red: (tag === '검사중'), green: (tag === '검사완료') }">
                    {{ tag.toUpperCase() }}
                </a-tag>
            </span>
            <span slot="name" slot-scope="text">{{ text }}</span>
            <span slot="barcode" slot-scope="barcode">{{ barcode }}</span>
            <span slot="updated" slot-scope="updated">{{ updated }}</span>
            <span slot="action" slot-scope="record">
                <!-- <span style = 'cursor:pointer' @click="handlerToConfirmProductCS(record.key, 'C')"><a-icon type="check-circle" /> Confirm</span>
                <a-divider type="vertical" /> -->
                <span style = 'cursor:pointer' @click="handlerToViewProductCS(record.key, 'C')"><a-icon type="edit" /> Edit</span>
                <a-divider type="vertical" />
                <span style = 'cursor:pointer' @click="handlerToDeleteProductCS(record.key, 'C')"><a-icon type="delete" /> Delete</span>
            </span>
        </a-table>
        <p/>
        <a-form class="" layout = "vertical" >
            <!-- <a-card>
                <a-form-item label="점검시트 작성 일자설정">
                    <a-space align="end">
                        <a-date-picker />
                        <a-radio-group style = ''>
                            <a-radio-button value="작성필요">작성필요 보기</a-radio-button>
                            <a-radio-button value="검사중">검사중 보기</a-radio-button>
                            <a-radio-button value="검사완료">검사완료 보기</a-radio-button>
                            <a-radio-button value="모두">모두보기</a-radio-button>
                        </a-radio-group>
                    </a-space>
                </a-form-item>
                <p/>
                <a-table :columns="columns2" size = "small" :bordered=false :data-source="data" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" >
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="action" slot-scope="">
                        <a-checkbox @change="onChange">
                            선택
                        </a-checkbox>
                    </span>
                </a-table>
            </a-card>
            <p/> -->
            <a-card>
                <a-space align="end">
                    초/중/종품 검사 성적서 조회
                    <a-input default-value="" v-model="filterCS" v-on:keyup="filterCSList">
                        <a-select slot="addonAfter" default-value="모델명" v-model="filterField" v-on:change="filterCSList">
                            <a-select-option value="모델명">모델명</a-select-option>
                            <a-select-option value="제품명">제품명</a-select-option>
                            <a-select-option value="검사결과">검사결과</a-select-option>
                            <!-- <a-select-option value="작성일자">작성일자</a-select-option>
                            <a-select-option value="작성상태">작성상태</a-select-option> -->
                        </a-select>
                    </a-input>

                    일자 조회
                    <a-input default-value="" v-model="filterDateStartCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input> ~
                    <a-input default-value="" v-model="filterDateEndCS" v-on:keyup="filterCSList" style="width:100px;" placeholder="0000-00-00"></a-input>
                </a-space>
                <p/>
                <a-table :columns="columns3" size = "small" :bordered=false :data-source="data3" :pagination="true" :scroll="{ x: 0, y: '57vh' }">
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag" class="state-btn" :class="{ red: (tag === '검사중'), green: (tag === '검사완료') }">
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="name" slot-scope="text">{{ text }}</span>
                    <span slot="action" slot-scope="record">
                        <span style = 'cursor:pointer' @click="handlerToViewProductCS(record.key, 'D')"><a-icon type="edit" /> Edit</span>
                        <a-divider type="vertical" />
                        <!-- <span style = 'cursor:pointer' @click="handlerToDeleteProductCS(record.key, 'D')"><a-icon type="delete" /> Delete</span> -->
                    </span>
                </a-table>
            </a-card>

        </a-form>
        <a-drawer title="" placement="right" :closable="false" :visible="flagForDrawer" @close="handlerForCloseDrawer" width="100%" >
            <FinishedViewer :target = "target" :pop = "pop" :showSaveBtn = true :showConfirmBtn = true v-on:flagForDrawer="handlerForCloseDrawer" v-on:editProductCS="handlerToEditProductCS" />
        </a-drawer>
    </div>
</template>
<script>
import _ from "lodash";
// import { Html5QrcodeScanner } from "html5-qrcode";
import PageHeader from "../components/PageHeader.vue";
import FinishedViewer from "./FinishedViewer.vue" ;
const columns = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '바코드' , dataIndex: 'barcode' , key: 'barcode' , scopedSlots: { customRender: 'name' },},
    {    title: '라인' , dataIndex: 'line' , key: 'line' , scopedSlots: { customRender: 'name' },},
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 150},
];
const columns2 = [
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 100},
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '이름'    ,   dataIndex: 'name' ,   key: 'name'     ,  scopedSlots: { customRender: 'name'        },},
];
const columns3 = [
    {    title: '상태'    ,   dataIndex: 'tags' ,   key: 'tags'     ,  scopedSlots: { customRender: 'tags'        },  width : 100},
    {    title: '모델명' , dataIndex: 'model' , key: 'model' , scopedSlots: { customRender: 'model' },},
    {    title: '세부모델명' , dataIndex: 'modelDetail' , key: 'modelDetail' , scopedSlots: { customRender: 'modelDetail' },},
    {    title: '제품명' , dataIndex: 'product' , key: 'product' , scopedSlots: { customRender: 'name' },},
    {    title: '바코드' , dataIndex: 'barcode' , key: 'barcode' , scopedSlots: { customRender: 'name' },},
    {    title: '라인' , dataIndex: 'line' , key: 'line' , scopedSlots: { customRender: 'name' },},
    {    title: '최종수정일',  dataIndex: 'updated', key: 'updated'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '검사결과',  dataIndex: 'checkState', key: 'checkState'  ,  scopedSlots: { customRender: 'updated'     },},
    {    title: '관리'                          ,   key: 'action'   ,  scopedSlots: { customRender: 'action'      },  width : 150},
];
const data = [
  {    key: '1',    name: 'FGCS210505-WP400-V1 보류중 20200401-15:30'   ,  tags: ['필요'],  },
  {    key: '2',    name: 'FGCS210505-WP301-V1 보류중 20200329-09:30'   ,  tags: ['필요'],  },
  {    key: '3',    name: 'PCCS210505-WP400-V1 완료 20200401-15:30'     ,  tags: ['진행'],  },
  {    key: '4',    name: 'PCCS210505-WP301-V1 완료 20200329-09:30'     ,  tags: ['완료'],  },
];
const data3 = [
  {    key: '1',    name: '정수기', model: 'WP-400',cs : 'FGCS210505-WP400-V1 ',id: '홍길동',created:'2021-01-01',updated:'2021-01-01' ,  tags: ['완료'],  },
  {    key: '2',    name: '정수기', model: 'WP-400',cs : 'FGCS210505-WP400-V1 ',id: '홍길동',created:'2021-01-01',updated:'2021-01-01' ,  tags: ['완료'],  },

];
export default {
    name: 'SheetFinished',
    components: {
        PageHeader ,
        FinishedViewer,
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            data,
            columns,
            columns2,
            columns3,
            data3,
            data3Backup: [],
            csArray: [],
            dataArray: [],
            dataArray3: [],
            pop: null,
            target: null,
            barcode: null,
            filterCS : '',
            filterField: '모델명',
            filterDateStartCS: '',
            filterDateEndCS: ''
        };
    },
    mounted() {
        this.pop = sessionStorage.getItem('pop') || '';
        this.barcode = sessionStorage.getItem('barcode') || '';
        
        this.isDataLoaded = true;
        this.fetchProductCSList();

        // 바코드 스캔
        function onScanSuccess(decodedText, decodedResult) {
            console.log(`Code scanned = ${decodedText}`, decodedResult);

            if (decodedText) {
                let temp = decodedText.split('=');
                let pop;
                // QR코드 인식
                if (temp.length > 1) {
                    pop = temp[1].substr(3, 5);
                    if (pop) {
                        sessionStorage.setItem('pop', pop);
                        sessionStorage.setItem('barcode', temp[1]);
                        location.reload();
                    }
                }
                // 바코드 인식
                else {
                    pop = decodedText.substr(3, 5);
                    if (pop) {
                        sessionStorage.setItem('pop', pop);
                        sessionStorage.setItem('barcode', decodedText);
                        location.reload();
                    }
                }
            }
        }
        
        /** 
         * You can visit https://www.dynamsoft.com/customer/license/trialLicense?utm_source=zip&product=dbr&package=js to get your own trial license good for 30 days. 
         * Note that if you downloaded this sample from Dynamsoft while logged in, the above license key may already be your own 30-day trial license.
         * For more information, see https://www.dynamsoft.com/barcode-reader/programming/javascript/user-guide/?ver=9.3.1&utm_source=zip#specify-the-license or contact support@dynamsoft.com.
         * LICENSE ALERT - THE END 
         */

        // scanner for decoding video
        let pScanner = null;
        (async() => {
            //Load the library on page load to speed things up.
            try {
                document.getElementById('lib-load').hidden = false;
                await window.Dynamsoft.DBR.BarcodeReader.loadWasm();
                startBarcodeScanner();
            } catch (ex) {
                let errMsg;
                if (ex.message.includes("network connection error")) {
                    errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                } else {
                    errMsg = ex.message||ex;
                }
                console.error(errMsg);
                alert(errMsg);
            }
            document.getElementById('showScanner').addEventListener('click', async() => {
                if (pScanner)(await pScanner).show();
            });
        })();
        // decode video from camera
        async function startBarcodeScanner() {
            try {
                let scanner = await (pScanner = pScanner || window.Dynamsoft.DBR.BarcodeScanner.createInstance());
                document.getElementById('showScanner').hidden = false;
                scanner.onFrameRead = (_results) => {
                    for (let result of _results) {
                        let newElements = [];
                        const format = result.barcodeFormat ? result.barcodeFormatString : result.barcodeFormatString_2;
                        newElements.push(createASpan(format + ": "));
                        newElements.push(createASpan(result.barcodeText, "resultText"));
                        newElements.push(document.createElement('br'));
                        if (result.barcodeText.indexOf("Attention(exceptionCode") != -1) {
                            newElements.push(createASpan(" Error: " + result.exception.message));
                            newElements.push(document.createElement('br'));
                        }
                    }
                };
                scanner.onUniqueRead = (txt, result) => {
                    onScanSuccess(txt, result);
                };
                document.getElementById('qr-reader').appendChild(scanner.getUIElement());
                await scanner.show();
                document.getElementById('lib-load').hidden = true;
            } catch (ex) {
                let errMsg;
                if (ex.message.includes("network connection error")) {
                    errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                } else {
                    errMsg = ex.message||ex;
                }
                console.error(errMsg);
                alert(errMsg);
            }
        }

        function createASpan(txt, className) {
            let newSPAN = document.createElement("span");
            newSPAN.textContent = txt;
            if (className)
                newSPAN.className = className;
            return newSPAN;
        }

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }
    },
    methods : {
        // showDrawer() {
        //     this.flagForDrawer = true;
        // },
        // closeDrawer() {
        //     this.flagForDrawer = false;
        // },
        // editTool() {
        //     console.log("## editTool in Tools ##");
        //     this.flagForDrawer = true;
        // },
        // deleteTool() {
        //     console.log("## deleteTool in Tools  ##");
        //     this.flagForDrawer = true;
        // }
        fetchProductCSList(skipBarcodeScan, forceView) {
            this.csArray = [];
            this.dataArray = [];
            this.data = [];
            this.data3 = [];
            let urlToFetchProductCSList = "https://coway.linkit.me:8100/product/cs/list_simple?size=10000&page=0";
            this.$http.get(urlToFetchProductCSList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                    this.csArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'B';
                    });
                    this.csArray = this.csArray.reverse();
                    this.dataArray = _.filter(response.data.data.content, (item) => {
                        return item.state === 'C';
                    });
                    this.dataArray = this.dataArray.reverse();
                    this.dataArray3 = _.filter(response.data.data.content, (item) => {
                        return item.state === 'D';
                    });
                    this.dataArray3 = this.dataArray3.reverse();

                    // 정렬
                    this.dataArray = _.orderBy(this.dataArray, ['updated'], ['desc']);
                    this.dataArray3 = _.orderBy(this.dataArray3, ['updated'], ['desc']);
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    let tags = ['검사중'];

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray[x].updated)).getTime() + 0 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    this.data.push({
                        key : x,
                        name : this.dataArray[x].name,
                        barcode : this.dataArray[x].barcode,
                        tags : tags,
                        // updated : this.dataArray[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray[x].clsProduct.productModel,
                        modelDetail : this.dataArray[x].clsProduct.productModelDetail,
                        product : this.dataArray[x].clsProduct.productName,
                        line : this.dataArray[x].line,
                        manager : this.dataArray[x].manager,
                        csTime : this.dataArray[x].csTime,
                    });
                }
                for(let x = 0; x < this.dataArray3.length ; x ++) {
                    let tags = ['검사완료'];

                    // TO DO - 서버에서 수정한 후 이 코드 삭제 - updated 가 9 시간이 작게 나와서 프론트엔드에서 수정
                    let updatedObj = new Date((new Date(this.dataArray3[x].updated)).getTime() + 0 * 60 * 60 * 1000);
                    let year = updatedObj.getFullYear();
                    let month = updatedObj.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    let date = updatedObj.getDate();
                    if (date < 10) {
                        date = '0' + date;
                    }
                    let hour = updatedObj.getHours();
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    let minute = updatedObj.getMinutes();
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    let second = updatedObj.getSeconds();
                    if (second < 10) {
                        second = '0' + second;
                    }

                    let checkState = 'OK';
                    // _.forEach(this.dataArray3[x].listProductCSInpection, function(item) {
                    //     if (item.listProductCSInpectionStandard[0].min === 'NG') {
                    //         checkState = 'NG';
                    //     }
                    // });
                    if (this.dataArray3[x].csTime.match('NG')) {
                        checkState = 'NG';
                    }

                    this.data3.push({
                        key : x,
                        name : this.dataArray3[x].name,
                        barcode : this.dataArray3[x].barcode,
                        tags : tags,
                        // updated : this.dataArray3[x].updated,
                        updated : year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
                        model : this.dataArray3[x].clsProduct.productModel,
                        modelDetail : this.dataArray3[x].clsProduct.productModelDetail,
                        product : this.dataArray3[x].clsProduct.productName,
                        line : this.dataArray3[x].line,
                        manager : this.dataArray3[x].manager,
                        csTime : this.dataArray3[x].csTime,
                        checkState: checkState
                    });

                    this.data3Backup = JSON.parse(JSON.stringify(this.data3));
                }
                this.flagSpinner = false;

                if (skipBarcodeScan && forceView) {   // 바코드 스캔 또는 직접 입력 후 체크시트가 생성된 후의 조회인 경우, 수정, 삭제인 경우
                    let target = this.dataArray[0];  // 방금 생성하였으므로 맨 처음 체크시트 조회 - 기존에는 맨 끝을 찾았으나 this.dataArray 정렬 순서 변경으로 맨 처음을 찾음
                    
                    let urlToFetchProductCSList = "https://coway.linkit.me:8100/product/cs/get_finished_cs_item?seq=" + target.productCsSeq;
                    this.$http.get(urlToFetchProductCSList)
                    .then((response) => {
                        if(response.status === 200 && response.data.code === 'success') {
                            let item = response.data.data.content[0];
                            this.target = item;
                            this.flagForDrawer = true;
                        }
                    });
                } else {
                    // 바코드 스캔한 경우
                    if (this.pop) {
                        let barcodeMatch = false;
                        let matchedCS = null;
                        let csItem = sessionStorage.getItem('finished_pop_item');
                        if (csItem) {
                            csItem = JSON.parse(csItem);
                            matchedCS = csItem;
                            if (csItem.clsProduct.productPop === this.pop) {
                                barcodeMatch = true;
                            }
                        }

                        // 바코드 매칭이 된 경우
                        if (barcodeMatch) {
                            if (confirm(this.barcode + ' 초/중/종품 체크시트를 작성하시겠습니까?')) {
                                // matchedCS 를 복사해서 valid 값을 C 로 하여 체크시트 생성 후 열기
                                this.handlerToCreateCSForProudct(matchedCS);
                            } else {
                                this.pop = '';
                                this.barcode = '';
                                sessionStorage.removeItem('pop');
                                sessionStorage.removeItem('finished_pop_item');
                                sessionStorage.removeItem('barcode');
                                location.reload();
                            }
                        } else {
                            this.$message.error('인식된 바코드의 제품이 등록되어 있지 않습니다.');
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        handlerToConfirmProductCS(dataOffset) {
            if (confirm('완료처리하시겠습니까?')) {
                this.data3.push({
                    key: this.dataArray[dataOffset].x,
                    name: this.dataArray[dataOffset].clsProduct.productName,
                    model: this.dataArray[dataOffset].clsProduct.productModel,
                    cs : this.dataArray[dataOffset].name,
                    id: '',
                    created: this.dataArray[dataOffset].created,
                    updated: this.dataArray[dataOffset].updated,
                    tags: ['완료']
                });

                this.data.splice(dataOffset, 1);
            }
        },
        handlerToViewProductCS(dataOffset, state) {
            this.dataOffset = dataOffset;
            let target;
            if (state === 'C') {
                target = this.dataArray[dataOffset];
            } else if (state === 'D') {
                target = this.dataArray3[dataOffset];
            }

            let urlToFetchProductCSList = "https://coway.linkit.me:8100/product/cs/get_finished_cs_item?seq=" + target.productCsSeq;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        this.target = item;
                        this.flagForDrawer = true;
                    }
                });
        },
        handlerToEditProductCS(listItem, state) {
            if (!listItem.line) {
                this.$message.error("라인을 선택해주십시오");
                this.flagForDrawer = true;
                return;
            }
            
            this.flagForDrawer = false;

            let generatedObjectToPersist = {
                productCsSeq: listItem.productCsSeq,
                productSeq: listItem.clsProduct.productSeq,
                productCSName: listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: state,
                barcode: listItem.barcode,
                csTime: listItem.csTime1,
                line: listItem.line,
                manager: listItem.manager,
            };

            let isNG = false;

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || '',
                        ngTitle: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].ngTitle || '',
                        ngContent: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].ngContent || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: item.inspectionImage2Seq,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);

                if (obj.standard.min.match('NG')) {
                    isNG = true;
                }
            });

            if (isNG) {
                generatedObjectToPersist.csTime = generatedObjectToPersist.csTime + 'NG';
            } else {
                generatedObjectToPersist.csTime = generatedObjectToPersist.csTime.split('NG')[0];
            }

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/edit';
            this.$http.put(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('저장되었습니다');
                    // this.fetchProductCSList(true);
                    location.reload();
                }  else {
                    this.$message.error('저장되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('저장되지 않았습니다(2).');
            });
        },
        handlerForCloseDrawer() {
            this.flagForDrawer = false;
        },
        handlerToDeleteProductCS(dataOffset, state) {
            if (state === 'C') {
                this.target = this.dataArray[dataOffset];
            } else if (state === 'D') {
                this.target = this.dataArray3[dataOffset];
            }

            if (confirm('삭제하시겠습니까?')) {
                let urlForProduct = this.$store.state.hostInService + '/product/cs/delete';
                let listItem = this.target;
                let generatedObjectToPersist = {
                    productCsSeq: listItem.productCsSeq
                };
                this.$http.put(urlForProduct,generatedObjectToPersist)
                    .then((response) => {
                        if (response.status === 200 && response.data.code === 'success') {
                            this.$message.success('삭제되었습니다');
                            this.fetchProductCSList(true);
                        }  else {
                            this.$message.error('삭제되지 않았습니다(1).');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('삭제되지 않았습니다(2).');
                    });
            }
        },
        handlerToCreateCSForProudctBarcode() {
            if (!this.barcode) {
                this.$message.error('바코드를 입력해주세요');
                return;
            }
            
            let pop = this.barcode.substr(3, 5);
            if (pop) {
                sessionStorage.setItem('pop', pop);
                sessionStorage.setItem('barcode', this.barcode);

                let urlToFetchProductCSList = "https://coway.linkit.me:8100/product/cs/get_finished_cs?pop=" + pop;
                this.$http.get(urlToFetchProductCSList)
                .then((response) => {
                    if(response.status === 200 && response.data.code === 'success') {
                        let item = response.data.data.content[0];
                        sessionStorage.setItem('finished_pop_item', JSON.stringify(item));
                        location.reload();
                    }
                });
            } else {
                this.$message.error('인식된 바코드의 제품이 등록되어 있지 않습니다.');
            }
        },
        handlerToCreateCSForProudct(listItem) {

            let generatedObjectToPersist = {
                productSeq: listItem.clsProduct.productSeq,
                productCSName: 'FINISHEDCS-' + listItem.name,
                productCSInspections: [],
                productCSTimes: [],
                state: 'C',
                barcode: this.barcode,
                csTime: ''
            };

            _.forEach(listItem.listProductCSInpection, (item) => {
                let obj = {
                    index: '' + item.inspectionIndex,
                    offset: '' + item.inspectionOffset,
                    inspection: item.inspectionName,
                    inspectionTarget: item.inspectionTarget || '',
                    standard: {
                        min: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].min || '',
                        max: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].max || '',
                        name: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].name || '',
                        result: item.listProductCSInpectionStandard.length && item.listProductCSInpectionStandard[0].result || ''
                    },
                    imageSeq: item.inspectionImageSeq,
                    image2Seq: 0,
                    description: item.inspectionDescription
                };

                generatedObjectToPersist.productCSInspections.push(obj);
            });

            _.forEach(listItem.listProductCSTime, (item) => {
                let obj = {
                    start: item.timeStart,
                    terms: item.timeTerms
                };

                generatedObjectToPersist.productCSTimes.push(obj);
            });


            let urlForProduct = this.$store.state.hostInService + '/product/cs/add';
            this.$http.post(urlForProduct,generatedObjectToPersist)
            .then((response) => {
                if (response.status === 200 && response.data.code === 'success') {
                    this.$message.success('생성되었습니다');
                    this.fetchProductCSList(true, true);
                    this.pop = '';
                    this.barcode = '';
                    sessionStorage.removeItem('pop');
                    sessionStorage.removeItem('finished_pop_item');
                    sessionStorage.removeItem('barcode');
                }  else {
                    this.$message.error('생성되지 않았습니다(1).');
                }
            })
            .catch(error => {
                console.error(error);
                this.$message.error('생성되지 않았습니다(2).');
            });
        },
        filterCSList() {
            if (this.filterCS || this.filterDateStartCS || this.filterDateEndCS) {
                let tempData = _.cloneDeep(this.data3Backup);

                if (this.filterCS) {
                    let re = new RegExp(this.filterCS, 'gi');

                    tempData = _.filter(tempData, (item) => {
                        // 모델명
                        if (this.filterField === '모델명') {
                            return item.model && item.model.match(re) || item.modelDetail && item.modelDetail.match(re);
                        }
                        // 제품명
                        else if (this.filterField === '제품명') {
                            return item.product && item.product.match(re);
                        }
                        // 검사결과
                        else if (this.filterField === '검사결과') {
                            return item.checkState === this.filterCS.toUpperCase();
                        }
                    });
                }

                if (this.filterDateStartCS) {
                    try {
                        let startTime = (new Date(this.filterDateStartCS)).getTime();
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() >= startTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (this.filterDateEndCS) {
                    try {
                        let endTime = (new Date(this.filterDateEndCS)).getTime() + 86400000;
                        tempData = _.filter(tempData, (item) => {
                            return (new Date(item.updated)).getTime() <= endTime;
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.data3 = tempData;
            } else {
                this.data3 = this.data3Backup;
            }
        }
    }
}
</script>
<style scoped>
    button#showScanner {
        position: absolute;
        width: 50%;
        left: 25%;
        top: 30%;
    }
</style>