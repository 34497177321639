/****************************************************************************************
 * File Name    : FinishedViewer.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="">
        <div @click="handlerForCloseDrawer()" style="position:absolute; top:4px; right:18px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px; z-index:10;">X</div>
        <div v-if = "flagSpinner === false">
            <a-card v-if="showSaveBtn" title = "공정순회 검사" style="position:relative;">
                <!--  -->
                <div class = 'todo'>상품정보 출력방식 변경</div>
                <a-list item-layout="horizontal" :data-source="data">
                    <a-list-item slot="renderItem">
                        <a-list-item-meta :description="target.clsProduct.productName">
                            <span slot="title">제품명</span>
                            <a-avatar slot="avatar" :src="productImagePath" ref=""/>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.clsProduct.productModel">
                            <span slot="title">모델명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.clsProduct.productModelDetail || '-'">
                            <span slot="title">세부모델명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.created">
                            <span slot="title">등록일자</span>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <div style="position:absolute; top: 12px; right:200px;">
                    라인
                    <select v-model="target.line" style="margin-left:5px; margin-right:20px; padding:4px;" >
                        <option value="A라인">A라인</option>
                        <option value="B라인">B라인</option>
                        <option value="C라인">C라인</option>
                        <option value="D라인">D라인</option>
                        <option value="E라인">E라인</option>
                        <option value="F라인">F라인</option>
                        <option value="G라인">G라인</option>
                        <option value="H라인">H라인</option>
                        <option value="I라인">I라인</option>
                        <option value="J라인">J라인</option>
                        <option value="K라인">K라인</option>
                        <option value="L라인">L라인</option>
                        <option value="M라인">M라인</option>
                        <option value="N라인">N라인</option>
                        <option value="O라인">O라인</option>
                        <option value="P라인">P라인</option>
                        <option value="Q라인">Q라인</option>
                        <option value="R라인">R라인</option>
                        <option value="S라인">S라인</option>
                        <option value="T라인">T라인</option>
                        <option value="U라인">U라인</option>
                        <option value="V라인">V라인</option>
                        <option value="W라인">W라인</option>
                        <option value="X라인">X라인</option>
                        <option value="Y라인">Y라인</option>
                        <option value="Z라인">Z라인</option>
                    </select>
                    담당자
                    <a-input v-model="target.manager" style="margin-left:5px; width: 80px"/>
                </div>
                <!--  -->
                <a-divider type = 'horizontal' />
                <div class = 'todo'>검사정보 출력방식 변경, 정렬</div>
                <!-- {{target.listProductCSInpection}} -->
                <table id="sheet-table" class="sheet-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th style="width:15%;">공정명</th>
                            <th style="width:20%;">검사 항목</th>
                            <th>참고 이미지</th>
                            <th style="padding-left:0; padding-right:0;">
                                <ul style="margin:0; padding:0; list-style:none; overflow:hidden;">
                                    <li style="float:left; width:180px; text-align:center;">09:00:~10:50</li>
                                    <li style="float:left; width:180px; text-align:center;">11:00:~12:30</li>
                                    <li style="float:left; width:180px; text-align:center;">13:30:~15:30</li>
                                    <li style="float:left; width:180px; text-align:center;">15:45:~18:00</li>
                                    <li style="float:left; width:180px; text-align:center;">18:30:~20:30</li>
                                </ul>
                            </th>
                            <th style="max-width:120px;"></th>
                            <th>사진</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr v-bind:class="{even: item.isEven}" v-for = "(item,offset) in target.listProductCSInpection" :key = "offset">
                            <td>{{item.inspectionTarget}}</td>
                            <td style="position:relative;">{{item.inspectionName}}</td>
                            <td>
                                <a-button v-if="item.inspectionImageSeq && item.inspectionImageSeq != '0'" @click="viewInspectionImage(offset)"> <a-icon type="picture" /> </a-button>
                            </td>
                            <td style="padding:0;">
                                <div v-if="item.inspectionTarget !== '4M'" style="overflow:hidden;">
                                    <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key="offset2" style="float:left; padding:5px;">
                                        <div v-if="offset2 % 3 === 0">
                                            <div v-if="showSaveBtn" style="display:inline-block;">
                                                <select v-model="item2.min" style="padding:4px; width: 120px" >
                                                    <option value="">  </option>
                                                    <option value="OK"> OK </option>
                                                    <option value="NG"> NG </option>
                                                </select>
                                                <br>
                                                <input type="text" v-model="item2.result" placeholder="" style="width: 120px"/>
                                                <br>
                                                <select v-model="item2.max"   style="padding:4px; width: 120px" >
                                                    <option value=""></option><option value="mm">mm</option>
                                                    <option value="cm">cm</option>
                                                    <option value="mA">mA</option>
                                                    <option value="A">A</option>
                                                    <option value="mV">mV</option>
                                                    <option value="V">V</option>
                                                    <option value="W">W</option>
                                                    <option value="kW">kW</option>
                                                    <option value="Ω">Ω</option>
                                                    <option value="kΩ">kΩ</option>
                                                    <option value="MΩ">MΩ</option>
                                                    <option value="ml">ml</option>
                                                    <option value="l">l</option>
                                                    <option value="mg">mg</option>
                                                    <option value="g">g</option>
                                                    <option value="℃">℃</option>
                                                    <option value="ppm">ppm</option>
                                                </select>
                                            </div>
                                            <div style="display:inline-block; width:30px; position: relative; top:-25px;">
                                                <div style="display:table-cell; vertical-align:middle;">
                                                    <a-icon v-if="item2.min === 'NG'" @click="viewNgNote(offset, offset2)" type="question-circle" style="margin-left:20px; font-size:20px; cursor:pointer;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.inspectionTarget === '4M'">
                                    <span>부품명</span>
                                    <input type="text" v-model="item.listProductCSInpectionStandard[0].min" style="margin-left:5px; width: 150px"/>
                                    <span style="margin-left:20px;">변경 내역</span>
                                    <input type="text" v-model="item.listProductCSInpectionStandard[0].result" style="margin-left:5px; width: 400px"/>
                                    <span style="margin-left:20px;">바코드</span>
                                    <input type="text" v-model="item.listProductCSInpectionStandard[0].max" style="margin-left:5px; width: 150px"/>
                                </div>
                            </td>
                            <td>
                                <div v-html="inspectionImageList2(offset, item.produtCsInspectionSeq)"></div>
                            </td>
                            <td>
                                <a-upload name="file" :multiple="false" :action="$store.state.imageUploadServer" v-on:change="imageUpload2(item)"  @change="imageUpload"
                                    v-decorator="['imageSeq2',{rules: [{ required: true, message: '이미지를 선택하세요' }],},]">
                                    <a-button> <a-icon type="camera" /> </a-button>
                                </a-upload>
                            </td>
                        </tr>
                        <!-- <a-tag v-if="item.inspectionDescription" color="">설명</a-tag> -->
                        <!-- <a-divider type = 'vertical' /> -->
                        <!-- {{item.inspectionDescription}}
                        <a-divider type = 'horizontal' /> -->
                    </tbody>
                </table>
                
                <div style="margin-top:20px; text-align:center;">
                    <a-button type="default" icon="plus-circle" size = "default" @click="handlerForCloseDrawer(target, 'G')" >
                        임시저장
                    </a-button>
                    <a-button type="primary" icon="plus-circle" size = "default" @click="handlerForCloseDrawer(target, 'H')" style="margin-left:20px;">
                        완료
                    </a-button>
                </div>
            </a-card>
            <a-card v-if="!showSaveBtn" :title = "target.name">
                <!--  -->
                <div class = 'todo'>상품정보 출력방식 변경</div>
                <a-list item-layout="horizontal" :data-source="data">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta :description="productDescription">
                            <span slot="title">{{ item.title }}</span>
                            <a-avatar slot="avatar" :src="productImagePath" ref=""/>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <!--  -->
                <a-divider type = 'horizontal' />
                <div class = 'todo'>검사정보 출력방식 변경, 정렬</div>
                <!-- {{target.listProductCSInpection}} -->
                <div v-for = "(item,offset) in target.listProductCSInpection" :key = "offset">
                    <a-tag color="#108ee9">검사대상 {{item.inspectionIndex}} </a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionTarget}}
                    <p/>
                    <a-tag color="#108ee9">검사항목 {{item.inspectionIndex}}-{{item.inspectionOffset}} </a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionName}}
                    <p/>
                    <div v-html="inspectionImageList(offset)"></div>
                    <p/>
                    <a-tag color="">검사기준</a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key = "offset2">
                        {{item2.name}}
                        <!-- <div v-if="!pop">
                            {{item2.min}} ~ {{item2.max}}
                            <br>
                            {{item2.result}}
                        </div>
                        <div v-if="pop"> -->
                        <div v-if="showSaveBtn">
                            <select v-model="item2.min"   style="padding:4px; width: 70px" >
                                <option value="OK"> OK </option>
                                <option value="NG"> NG </option>
                            </select>
                            <a-input v-model="item2.result" placeholder="" style="margin-left: 20px; width: 100px"/>
                            <select v-model="item2.max"   style="margin-left:5px; padding:4px; width: 50px" >
                                <option value=""></option><option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="mA">mA</option>
                                <option value="A">A</option>
                                <option value="mV">mV</option>
                                <option value="V">V</option>
                                <option value="W">W</option>
                                <option value="kW">kW</option>
                                <option value="Ω">Ω</option>
                                <option value="kΩ">kΩ</option>
                                <option value="MΩ">MΩ</option>
                                <option value="ml">ml</option>
                                <option value="l">l</option>
                                <option value="mg">mg</option>
                                <option value="g">g</option>
                                <option value="℃">℃</option>
                                <option value="ppm">ppm</option>
                            </select>
                            <!-- <a-input v-model="item2.min" placeholder="" style="width: 50px"/>
                            -
                            <a-input v-model="item2.max" placeholder="" style="width: 50px"/>
                            <br>
                            <a-input v-model="item2.result" placeholder="" style="width: 300px"/> -->
                        </div>
                    </div>
                    <p/>
                    <a-tag v-if="item.inspectionDescription" color="">설명</a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionDescription}}
                    <a-divider type = 'horizontal' />
                </div>
            </a-card>
        </div>
        <a-skeleton v-else />
        <div id="image-viewer-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">
            <div id="image-viewer" style="position:absolute; top:20%; left:20%; width:60%; height:60%; background-color:#fff; border:1px solid #ccc;">
                <div id="image-viewer-close" @click="imageViewerClose()" style="position:absolute; top:23px; right:20px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px;">X</div>
                <div id="image-viewer-content" style="height:100%; overflow:auto;"></div>
            </div>
        </div>
        <div id="ng-note-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">	
            <div id="ng-note" style="position:absolute; top:20%; left:20%; width:60%; height:60%; background-color:#fff; border:1px solid #ccc;">	
                <div id="ng-note-close" @click="ngNoteClose()" style="position:absolute; top:10px; right:20px; cursor:pointer; font-size:30px; font-weight:bold; z-index:10;">X</div>	
                <a-card title = "NG 기록하기">	
                    <a-input v-model="ngTitle" placeholder="제목" />	
                    <a-textarea v-model="ngContent" placeholder="NG 내용 기록" rows="17" style="margin-top:20px;"></a-textarea>	
                    <div style="margin-top:20px; text-align:center;">	
                        <a-button @click="ngNoteClose()" type="default" icon="plus-circle" size = "default" >	
                            취소	
                        </a-button>	
                        <a-button @click="saveNgNote()" type="primary" icon="plus-circle" size = "default" style="margin-left:20px;">	
                            완료	
                        </a-button>	
                    </div>	
                </a-card>	
            </div>	
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
const data = [{title:'제품명'}];
export default {
    name: 'Patrol4mViewer',
    props : ['target', 'flagForDrawer', 'pop', 'showSaveBtn', 'showConfirmBtn'],
    components: {
    },
    data() {
        return {
            flagSpinner : false,
            data,
            fileUploadSeq   : 0,
            fileUploadItem : null,
            ngTitle: '',	
            ngContent: '',	
            offset: 0,	
            offset2: 0,
            uploadFileSeq: []
        };
    },
    mounted() {
        this.setListProductCSInpection();

        if (!this.target.manager) {
            this.target.manager = sessionStorage.getItem('user_name');
        }
    },
    watch: {
        target: function(newOne){
            this.target = newOne;

            this.setListProductCSInpection();
        }
    },
    computed : {
        productDescription() {
            let buffer = this.target.clsProduct.productName + " (" + this.target.clsProduct.productModel + ") ";
            for(let x = 0; x < this.target.clsProduct.listProductFunction.length; x ++ ) {
                buffer = buffer + this.target.clsProduct.listProductFunction[x].productFunctionName + ",";
            }
            return buffer;
        },
        productImagePath() {
            return this.$store.state.hostInService + '/upload/image/download/' + this.target.clsProduct.uploadFileSeq;
        },

    },
    methods : {
        setListProductCSInpection() {
            this.target.listProductCSInpection = _.sortBy(this.target.listProductCSInpection, ['inspectionIndex', 'inspectionOffset']);

            // 4M 이 맨 아래 위치하도록 처리
            let fourMItem = _.find(this.target.listProductCSInpection, {
                inspectionTarget: '4M'
            });
            this.target.listProductCSInpection = _.reject(this.target.listProductCSInpection, {
                inspectionTarget: '4M'
            });
            if (fourMItem) {
                this.target.listProductCSInpection.push(fourMItem);
            }

            // let isEven = false;
            // let oldInspectionTarget = '';
            // _.forEach(this.target.listProductCSInpection, (item) => {
            //     if (!oldInspectionTarget) {
            //         oldInspectionTarget = item.inspectionTarget;
            //     }

            //     if (item.inspectionTarget !== oldInspectionTarget && item.inspectionTarget) {
            //         oldInspectionTarget = item.inspectionTarget;

            //         isEven = !isEven;
            //     }

            //     item.isEven = isEven;
            // });

            let isEven = false;
            let oldInspectionIndex = '';
            _.forEach(this.target.listProductCSInpection, (item) => {
                if (!oldInspectionIndex) {
                    oldInspectionIndex = item.inspectionIndex;
                }

                if (item.inspectionIndex !== oldInspectionIndex) {
                    oldInspectionIndex = item.oldInspectionIndex;

                    isEven = !isEven;
                }

                item.isEven = isEven;
            });
        },
        inspectionImageList(offset) {
            let html = '';
            let imageSeq = [];
            if (this.target.listProductCSInpection[offset].inspectionImageSeq && this.target.listProductCSInpection[offset].inspectionImageSeq != '0') {
                imageSeq = this.target.listProductCSInpection[offset].inspectionImageSeq.split('||');
            }

            _.forEach(imageSeq, (item) => {
                let src = this.$store.state.hostInService +'/upload/image/download/' + item;
                html += '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';
            });

            return html;
        },
        inspectionImageList2(offset) {
            let self = this;

            let html = '';
            let imageSeq = [];
            if (self.target.listProductCSInpection[offset].inspectionImage2Seq && self.target.listProductCSInpection[offset].inspectionImage2Seq != '0') {
                imageSeq = self.target.listProductCSInpection[offset].inspectionImage2Seq.split('||');
            }

            _.forEach(imageSeq, (item) => {
                if (item == '0') {
                    return;
                }
                
                let src = self.$store.state.hostInService +'/upload/image/download/' + item;
                html += '<p style="margin-bottom:0;"><a href = "' + src + '" target="_blank" id="view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '"><img src = "' + src + '" style = "width:30px; height:30px;"/></a></p><span id="delete-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '" style="display:block; padding-top:10px; font-size:12px; cursor:pointer;">삭제</span>';

                ((self) => {
                    // 이미지 보기
                    $('body').off('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item).on('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item, '#view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item , function(e) {
                        e.preventDefault();

                        let html = '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';

                        self.viewInspectionImage2(html);
                    });

                    // 이미지 삭제
                    $('body').off('click.delete-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item).on('click.delete-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item, '#delete-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item , function() {
                        if (confirm('삭제하시겠습니까?')) {
                            imageSeq.splice(imageSeq.indexOf('' + item), 1);
                            
                            html = '';
                            _.forEach(imageSeq, (item) => {
                                let src = self.$store.state.hostInService +'/upload/image/download/' + item;
                                html += '<p style="margin-bottom:0;"><a href = "' + src + '" target="_blank" id="view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '"><img src = "' + src + '" style = "width:30px; height:30px;"/></a></p><span id="delete-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '" style="display:block; padding-top:10px; font-size:12px; cursor:pointer;">삭제</span>';
                            });

                            self.target.listProductCSInpection[offset].inspectionImage2Seq = imageSeq.join('||');

                            self.inspectionImageList2(offset);
                        }
                    });
                })(self);
            });

            return html;
        },
        productInspectionPath(offset) {
            return this.$store.state.hostInService +'/upload/image/download/' + this.target.listProductCSInpection[offset].inspectionImageSeq;
        },
        productInspectionPath2(offset) {
            return this.$store.state.hostInService +'/upload/image/download/' + this.target.listProductCSInpection[offset].inspectionImage2Seq;
        },
        handlerForCloseDrawer(target, state) {
            this.$emit('flagForDrawer');

            if (target) {
                this.$emit('editPatrolCS', target, state);
            }
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                let temp = this.fileUploadItem.inspectionImage2Seq.split('||');
                temp.push(info.file.response.data.uploadFileSeq);
                this.fileUploadItem.inspectionImage2Seq = temp.join('||');
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        imageUpload2(item) {
            this.fileUploadItem = item;
        },
        viewInspectionImage(offset) {
            let html = this.inspectionImageList(offset);

            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-content').innerHTML = html;
        },
        viewInspectionImage2(html) {
            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-content').innerHTML = html;
        },
        imageViewerClose() {
            document.getElementById('image-viewer-container').style.display = 'none';
            document.getElementById('image-viewer-content').innerHTML = '';
        },
        viewNgNote(offset, offset2) {
            this.offset = offset;
            this.offset2 = offset2;

            var item = this.target.listProductCSInpection[offset].listProductCSInpectionStandard[offset2];

            this.ngTitle = item.ngTitle;
            this.ngContent = item.ngContent;

            document.getElementById('ng-note-container').style.display = 'block';
        },
        saveNgNote() {
            var item = this.target.listProductCSInpection[this.offset].listProductCSInpectionStandard[this.offset2];

            item.ngTitle = this.ngTitle;
            item.ngContent = this.ngContent;

            this.ngNoteClose();
        },
        ngNoteClose() {
            this.ngTitle = '';
            this.ngContent = '';

            document.getElementById('ng-note-container').style.display = 'none';
        }
    }
}
</script>
<style scoped>
    #sheet-table th {
        font-weight: bold;
    }
    
    #sheet-table tr.even {	
        background-color: #e8f7ff;	
    }

    .bg-even {
        background-color: #e8f7ff;
    }
</style>