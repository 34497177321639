/****************************************************************************************
 * File Name    : FinishedViewer.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="">
        <div @click="handlerForCloseDrawer()" style="position:absolute; top:4px; right:18px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px; z-index:10;">X</div>
        <div v-if = "flagSpinner === false">
            <a-card title = "설비 검사">
                <a-form v-if="mode === 'create'" class="" :form="form" layout = "vertical">
                    <a-card>
                        <div class="ant-col ant-form-item-label">
                            <label title="라인" class="">라인</label>
                            <select v-model="target.equipmentLineInfoSeq" @change="createLineChange" style="margin-left:10px;">
                                <option v-for="(item,index) in lineinfo[this.targetProduct]" :key="index" :value="item.equipmentLineInfoSeq">{{item.lineName}}</option>
                            </select>
                            <label title="담당자" class="" style="margin-left:20px;">담당자</label>
                            <span style="margin-left:10px;">
                                <a-input v-model="target.creator" style="margin-left:5px; width: 80px"/>
                            </span>
                        </div>
                        <p />
                        <a-table :columns="columns1" size = "small" :bordered=false :data-source="target.listEquipmentInspectionComponent" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                            <span slot="action" slot-scope="record">
                                <select v-model="record.result1"   style="padding:4px; width: 50px" >
                                    <option value=""> 월 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result2"   style="padding:4px; width: 50px" >
                                    <option value=""> 화 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result3"   style="padding:4px; width: 50px" >
                                    <option value=""> 수 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result4"   style="padding:4px; width: 50px" >
                                    <option value=""> 목 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result5"   style="padding:4px; width: 50px" >
                                    <option value=""> 금 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <input type="text" v-model="record.action" placeholder="조치 내용" style="margin-left: 20px; width: 150px"/>
                            </span>
                        </a-table>
                    </a-card>
                </a-form>
                <a-form v-if="mode === 'edit'" class="" :form="form" layout = "vertical">
                    <a-card>
                        <div class="ant-col ant-form-item-label">
                            <label title="라인" class="">라인</label>
                            <select v-model="target.clsEquipmentLineInfo.equipmentLineInfoSeq" @change="editLineChange" style="margin-left:10px;">
                                <option v-for="(item,index) in lineinfo[this.targetProduct]" :key="index" :value="item.equipmentLineInfoSeq">{{item.lineName}}</option>
                            </select>
                            <label title="담당자" class="" style="margin-left:20px;">담당자</label>
                            <span style="margin-left:10px;">
                                <a-input v-model="target.creator" style="margin-left:5px; width: 80px"/>
                            </span>
                        </div>
                        <p />
                        <a-table :columns="columns1" size = "small" :bordered=false :data-source="target.listEquipmentInspectionComponent" :pagination="false" :scroll="{ x: 0, y: '57vh' }">
                            <span slot="action" slot-scope="record">
                                <select v-model="record.result1"   style="padding:4px; width: 50px" >
                                    <option value=""> 월 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result2"   style="padding:4px; width: 50px" >
                                    <option value=""> 화 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result3"   style="padding:4px; width: 50px" >
                                    <option value=""> 수 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result4"   style="padding:4px; width: 50px" >
                                    <option value=""> 목 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <select v-model="record.result5"   style="padding:4px; width: 50px" >
                                    <option value=""> 금 </option>
                                    <option value="good"> ○ </option>
                                    <option value="warn"> △ </option>
                                    <option value="notgood"> Χ </option>
                                </select>
                                <a-input v-model="record.action" placeholder="조치 내용" style="margin-left: 20px; width: 150px"/>
                            </span>
                        </a-table>
                    </a-card>
                </a-form>
                
                <div style="margin-top:20px; text-align:center;">
                    <a-button type="default" icon="plus-circle" size = "default" @click="handlerForCloseDrawer()" >
                        취소
                    </a-button>
                    <a-button type="default" icon="plus-circle" size = "default" @click="submit('A')" style="margin-left:20px;">
                        임시저장
                    </a-button>
                    <a-button type="primary" icon="plus-circle" size = "default" @click="submit('B')" style="margin-left:20px;">
                        완료
                    </a-button>
                </div>
            </a-card>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
const data = [{title:'제품명'}];
const columns1 = [
    { title: '공정',dataIndex: 'processName',key: 'processName',  scopedSlots: { customRender: 'processName'},},
    { title: '단위설비',dataIndex: 'unitEquipment',key: 'unitEquipment',  scopedSlots: { customRender: 'unitEquipment'},},
    { title: '구성장치',dataIndex: 'devivce',key: 'devivce',  scopedSlots: { customRender: 'devivce'},},
    { title: '점검내용',dataIndex: 'inspectionDescription',key: 'inspectionDescription',scopedSlots: { customRender: 'inspectionDescription'},},
    { title: '주기',dataIndex: 'inspectionCycle',key: 'inspectionCycle',scopedSlots: { customRender: 'inspectionCycle'},},
    { title: '관리',key: 'action',scopedSlots: { customRender: 'action'},  width : 500},
];

export default {
    name: 'EquipmentViewer',
    props: ['equipments', 'lineinfo', 'showSaveBtn', 'target', 'flagForDrawer', 'targetProduct'],
    components: {
    },
    data() {
        return {
            flagSpinner : false,
            data,
            fileUploadSeq   : 0,
            fileUploadItem : null,
            ngTitle: '',	
            ngContent: '',	
            offset: 0,	
            offset2: 0,
            columns1,
            mode: 'create',
            createLineManager: ''
        };
    },
    beforeMount() {
        console.log('equipments', this.equipments, 'lineinfo', this.lineinfo, this.target.equipmentInspectionSeq)

        if (!this.target.equipmentInspectionSeq) {
            this.target.equipmentInspectionSeq = this.lineinfo[this.targetProduct][0].equipmentInspectionSeq;
            this.mode = 'create';
            this.target.listEquipmentInspectionComponent = _.cloneDeep(this.equipments[this.targetProduct]);
            this.target.creator = sessionStorage.getItem('user_name');
        } else {
            this.mode = 'edit';

            _.forEach(this.target.listEquipmentInspectionComponent, (item) => {
                item.processName = item.clsEquipment.processName;
                item.unitEquipment = item.clsEquipment.unitEquipment;
                item.device = item.clsEquipment.device;
                item.inspectionDescription = item.clsEquipment.inspectionDescription;
                item.inspectionCycle = item.clsEquipment.inspectionCycle;
            });

            this.target.listEquipmentInspectionComponent = _.orderBy(this.target.listEquipmentInspectionComponent, (item) => {
                return item.clsEquipment.equipmentSeq;
            });
        }

        _.forEach(this.target.listEquipmentInspectionComponent, (item, i) => {
            item.key = i;   // 테이블 표시를 위한 key 생성

            if (!item.result) {
                item.result = '||||||||';
            }

            let temp = item.result.split('||');
            item.result1 = temp[0];
            item.result2 = temp[1];
            item.result3 = temp[2];
            item.result4 = temp[3];
            item.result5 = temp[4];
        });
    },
    mounted() {
        
    },
    watch: {
        targetProduct: function(newOne){
            this.targetProduct = newOne;
        },
        target: function(newOne){
            this.target = newOne;

            if (!this.target.equipmentInspectionSeq) {
                this.target.equipmentInspectionSeq = this.lineinfo[this.targetProduct][0].equipmentInspectionSeq;
                this.mode = 'create';
                this.target.listEquipmentInspectionComponent = _.cloneDeep(this.equipments[this.targetProduct]);
                this.target.creator = sessionStorage.getItem('user_name');
            } else {
                this.mode = 'edit';

                _.forEach(this.target.listEquipmentInspectionComponent, (item) => {
                    item.processName = item.clsEquipment.processName;
                    item.unitEquipment = item.clsEquipment.unitEquipment;
                    item.device = item.clsEquipment.device;
                    item.inspectionDescription = item.clsEquipment.inspectionDescription;
                    item.inspectionCycle = item.clsEquipment.inspectionCycle;
                });

                this.target.listEquipmentInspectionComponent = _.orderBy(this.target.listEquipmentInspectionComponent, (item) => {
                    return item.clsEquipment.equipmentSeq;
                });
            }

            _.forEach(this.target.listEquipmentInspectionComponent, (item, i) => {
                item.key = i;   // 테이블 표시를 위한 key 생성

                if (!item.result) {
                    item.result = '||||||||';
                }

                let temp = item.result.split('||');
                item.result1 = temp[0];
                item.result2 = temp[1];
                item.result3 = temp[2];
                item.result4 = temp[3];
                item.result5 = temp[4];
            });
        },
    },
    computed : {
        productDescription() {
            let buffer = this.target.clsProduct.productName + " (" + this.target.clsProduct.productModel + ") ";
            for(let x = 0; x < this.target.clsProduct.listProductFunction.length; x ++ ) {
                buffer = buffer + this.target.clsProduct.listProductFunction[x].productFunctionName + ",";
            }
            return buffer;
        },
        productImagePath() {
            return this.$store.state.hostInService + '/upload/image/download/' + this.target.clsProduct.uploadFileSeq;
        },

    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
    },
    methods : {
        timeToDatestr(timestamp) {
            let dateObj = new Date(timestamp);

            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }
            let hour = dateObj.getHours();
            if (hour < 10) {
                hour = '0' + hour;
            }
            let minute = dateObj.getMinutes();
            if (minute < 10) {
                minute = '0' + minute;
            }
            let second = dateObj.getSeconds();
            if (second < 10) {
                second = '0' + second;
            }

            return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
        },
        createLineChange() {
            if (this.target.equipmentLineInfoSeq) {
                let item = _.find(this.lineinfo[this.targetProduct], {
                    equipmentLineInfoSeq: this.target.equipmentLineInfoSeq
                });

                if (item) {
                    this.createLineManager = item.manager;
                }
            } else {
                this.createLineManager = '';
            }
        },
        editLineChange() {
            let item = _.find(this.lineinfo[this.targetProduct], {
                equipmentLineInfoSeq: this.target.clsEquipmentLineInfo.equipmentLineInfoSeq
            });

            if (item) {
                this.target.clsEquipmentLineInfo.manager = item.manager;
            }
        },
        getDateStr(dateObj) {
            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = '0' + date;
            }
            let hour = dateObj.getHours();
            if (hour < 10) {
                hour = '0' + hour;
            }
            let minute = dateObj.getMinutes();
            if (minute < 10) {
                minute = '0' + minute;
            }
            let second = dateObj.getSeconds();
            if (second < 10) {
                second = '0' + second;
            }

            return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
        },
        submit(valid) {
            let apiUrl;

            if (this.mode === 'create') {
                if (!this.target.equipmentLineInfoSeq) {
                    this.$message.error("라인을 선택해주십시오");
                    return;
                }

                let createData = {
                    "equipmentInspectionSeq": 0,
                    "inspectionDate": this.getDateStr(new Date()),
                    "equipmentLineInfoSeq": this.target.equipmentLineInfoSeq,
                    "listEquipmentInspectionComponent": [],
                    "creator": this.target.creator,
                    "updated2": this.timeToDatestr(new Date()),
                    "valid": valid
                };

                _.forEach(this.target.listEquipmentInspectionComponent, (item) => {
                    createData.listEquipmentInspectionComponent.push({
                        "equipmentSeq": item.equipmentSeq,
                        "result": item.result1 + '||' + item.result2 + '||' + item.result3 + '||' + item.result4 + '||' + item.result5,
                        "action": item.action
                    });
                });

                apiUrl = this.$store.state.hostInService + "/equipment/inspection/add";
                this.$http.post(apiUrl,createData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("생성되었습니다.");
                    this.$emit('editEquipmentSheet');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("생성되지 않았습니다.");
                });
            } else {
                let editData = JSON.parse(JSON.stringify(this.target));
                // editData.equipmentSeq = editData.listEquipmentInspectionComponent[0].clsEquipment.equipmentSeq;
                editData.equipmentLineInfoSeq = editData.clsEquipmentLineInfo.equipmentLineInfoSeq;

                // 이전 상태가 검사완료 또는 승인완료 이거나 이번에 변경할 상태가 승인완료일 경우 시간을 업데이트 하지 않음
                if (editData.valid === 'B' || editData.valid === 'C' || valid === 'C') {
                    // 시간을 업데이트 하지 않음
                } else {
                    editData.updated2 = this.timeToDatestr(new Date());
                }

                editData.valid = valid;

                delete(editData.clsEquipmentLineInfo);
                delete(editData.devivce);
                delete(editData.inspectionCycle);
                delete(editData.inspectionDescription);
                delete(editData.processName);
                delete(editData.state);
                delete(editData.targetProduct);
                delete(editData.unitEquipment);

                _.forEach(editData.listEquipmentInspectionComponent, function(item) {
                    item.equipmentSeq = item.clsEquipment.equipmentSeq;
                    item.result = item.result1 + '||' + item.result2 + '||' + item.result3 + '||' + item.result4 + '||' + item.result5;
                    delete(item.clsEquipment);
                    delete(item.result1);
                    delete(item.result2);
                    delete(item.result3);
                    delete(item.result4);
                    delete(item.result5);
                });
                
                apiUrl = this.$store.state.hostInService + "/equipment/inspection/edit";
                this.$http.put(apiUrl,editData)
                .then((response) => {
                    console.log(response);
                    if(response.status === 200 && response.data.code === 'success') {
                        this.buffer = response.data.data;
                    }
                    this.$message.success("수정되었습니다.");
                    this.$emit('editEquipmentSheet');
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error("수정되지 않았습니다.");
                });
            }
        },
        handlerForCloseDrawer(target, state) {
            this.$emit('flagForDrawer');

            if (target) {
                this.$emit('editPatrolCS', target, state);
            }
        },
    }
}
</script>
<style scoped>
    #sheet-table th {
        font-weight: bold;
    }
    
    #sheet-table tr.even {	
        background-color: #e8f7ff;	
    }

    .bg-even {
        background-color: #e8f7ff;
    }
</style>