/****************************************************************************************
 * File Name    : FinishedViewer.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div class="report-viewer" id="print">
        <div @click="handlerForCloseDrawer()" style="position:absolute; top:4px; right:18px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px; z-index:10;">X</div>
        <div v-if = "flagSpinner === false">
            <a-card v-if="showSaveBtn" title = "초/중/종품 검사 보고서" style="position:relative;">
                <!--  -->
                <div class = 'todo'>상품정보 출력방식 변경</div>
                <a-list item-layout="horizontal" :data-source="data">
                    <a-list-item slot="renderItem">
                        <a-list-item-meta :description="target.manager || '-'">
                            <span slot="title">검사담당자</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.approver || '-'">
                            <span slot="title">승인자</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.line || '-'">
                            <span slot="title">라인명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.csTime.split('NG')[0] || '-'">
                            <span slot="title">검사대상</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.updated">
                            <span slot="title">검사완료일시</span>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <a-divider type = 'horizontal' />
                <a-list item-layout="horizontal" :data-source="data">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta :description="target.clsProduct.productName">
                            <span slot="title">{{ item.title }}</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.clsProduct.productModel">
                            <span slot="title">모델명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.clsProduct.productModelDetail || '-'">
                            <span slot="title">세부모델명</span>
                        </a-list-item-meta>
                        <a-list-item-meta :description="target.barcode || '-'">
                            <span slot="title">바코드</span>
                        </a-list-item-meta>
                        <a-list-item-meta>
                            <span slot="title"></span>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <!--  -->
                <a-divider type = 'horizontal' />
                <div class = 'todo'>검사정보 출력방식 변경, 정렬</div>
                <!-- {{target.listProductCSInpection}} -->
                <table id="sheet-table" class="report-table" style='width:100%; border: 1px solid #e8e8e8'>
                    <thead class="ant-table-thead">
                        <tr>
                            <th style="width:10%;">검사 대상</th>
                            <th style="width:15%;">검사 항목</th>
                            <th>참고 이미지</th>
                            <th style="width:25%;">검사 기준</th>
                            <th style="width:80px;">검사 결과</th>
                            <th style="width:20%;">부적합 내역</th>
                            <th>검사 이미지</th>
                        </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                        <tr v-bind:class="{even: item.isEven}" v-for = "(item,offset) in target.listProductCSInpection" :key = "offset">
                            <td>
                                {{
                                    item.inspectionTarget ||
                                    target.listProductCSInpection[offset - 1].inspectionTarget ||
                                    target.listProductCSInpection[offset - 2].inspectionTarget ||
                                    target.listProductCSInpection[offset - 3].inspectionTarget ||
                                    target.listProductCSInpection[offset - 4].inspectionTarget ||
                                    target.listProductCSInpection[offset - 5].inspectionTarget ||
                                    target.listProductCSInpection[offset - 6].inspectionTarget ||
                                    target.listProductCSInpection[offset - 7].inspectionTarget ||
                                    target.listProductCSInpection[offset - 8].inspectionTarget ||
                                    target.listProductCSInpection[offset - 9].inspectionTarget ||
                                    target.listProductCSInpection[offset - 10].inspectionTarget
                                }}
                            </td>
                            <td style="position:relative;">
                                {{
                                    item.inspectionName ||
                                    target.listProductCSInpection[offset - 1].inspectionName ||
                                    target.listProductCSInpection[offset - 2].inspectionName ||
                                    target.listProductCSInpection[offset - 3].inspectionName ||
                                    target.listProductCSInpection[offset - 4].inspectionName ||
                                    target.listProductCSInpection[offset - 5].inspectionName ||
                                    target.listProductCSInpection[offset - 6].inspectionName ||
                                    target.listProductCSInpection[offset - 7].inspectionName ||
                                    target.listProductCSInpection[offset - 8].inspectionName ||
                                    target.listProductCSInpection[offset - 9].inspectionName ||
                                    target.listProductCSInpection[offset - 10].inspectionName
                                }}
                            </td>
                            <td>
                                <div v-html="inspectionImageList(offset)"></div>
                            </td>
                            <td>
                                <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key = "offset2">
                                    {{item2.name}}
                                </div>
                            </td>
                            <td>
                                <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key = "offset2">
                                    <!-- <div v-if="!pop">
                                        {{item2.min}} ~ {{item2.max}}
                                        <br>
                                        {{item2.result}}
                                    </div>
                                    <div v-if="pop"> -->
                                    <div v-if="showSaveBtn">
                                        <span style="margin:0 10px;">{{item2.min}}</span>
                                        <span style="margin:0 10px;">{{item2.result}} {{item2.max}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key = "offset2">
                                    <!-- <div v-if="!pop">
                                        {{item2.min}} ~ {{item2.max}}
                                        <br>
                                        {{item2.result}}
                                    </div>
                                    <div v-if="pop"> -->
                                    <div v-if="showSaveBtn">
                                        <span>{{item2.ngTitle}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div v-html="inspectionImageList2(offset)"></div>
                            </td>
                        </tr>
                        <!-- <a-tag v-if="item.inspectionDescription" color="">설명</a-tag> -->
                        <!-- <a-divider type = 'vertical' /> -->
                        <!-- {{item.inspectionDescription}}
                        <a-divider type = 'horizontal' /> -->
                    </tbody>
                </table>
                
                <div id="print-button-container" style="margin-top:20px; text-align:center;">
                    <a-button type="default" icon="plus-circle" size = "default" @click="handlerForCloseDrawer()" >
                        취소
                    </a-button>
                    <a-button type="default" icon="plus-circle" size = "default" @click="print()" style="margin-left:20px;">
                        인쇄
                    </a-button>
                    <a-button v-if="target.state !== 'J'" type="primary" icon="plus-circle" size = "default" @click="handlerForCloseDrawer(target, 'J')" style="margin-left:20px;">
                        승인완료
                    </a-button>
                    <a-button v-if="target.state === 'J'" type="danger" icon="plus-circle" size = "default" @click="handlerForCloseDrawer(target, 'D')" style="margin-left:20px;">
                        승인완료 취소
                    </a-button>
                </div>
            </a-card>
            <a-card v-if="!showSaveBtn" :title = "target.name">
                <!--  -->
                <div class = 'todo'>상품정보 출력방식 변경</div>
                <a-list item-layout="horizontal" :data-source="data">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta :description="productDescription">
                            <span slot="title">{{ item.title }}</span>
                            <a-avatar slot="avatar" :src="productImagePath" ref=""/>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
                <!--  -->
                <a-divider type = 'horizontal' />
                <div class = 'todo'>검사정보 출력방식 변경, 정렬</div>
                <!-- {{target.listProductCSInpection}} -->
                <div v-for = "(item,offset) in target.listProductCSInpection" :key = "offset">
                    <a-tag color="#108ee9">검사대상 {{item.inspectionIndex}} </a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionTarget}}
                    <p/>
                    <a-tag color="#108ee9">검사항목 {{item.inspectionIndex}}-{{item.inspectionOffset}} </a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionName}}
                    <p/>
                    <div v-html="inspectionImageList(offset)"></div>
                    <p/>
                    <a-tag color="">검사기준</a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    <div v-for = "(item2,offset2) in item.listProductCSInpectionStandard" :key = "offset2">
                        {{item2.name}}
                        <!-- <div v-if="!pop">
                            {{item2.min}} ~ {{item2.max}}
                            <br>
                            {{item2.result}}
                        </div>
                        <div v-if="pop"> -->
                        <div v-if="showSaveBtn">
                            <select v-model="item2.min"   style="padding:4px; width: 70px" >
                                <option value="OK"> OK </option>
                                <option value="NG"> NG </option>
                            </select>
                            <a-input v-model="item2.result" placeholder="" style="margin-left: 20px; width: 100px"/>
                            <select v-model="item2.max"   style="margin-left:5px; padding:4px; width: 50px" >
                                <option value=""></option><option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="mA">mA</option>
                                <option value="A">A</option>
                                <option value="mV">mV</option>
                                <option value="V">V</option>
                                <option value="W">W</option>
                                <option value="kW">kW</option>
                                <option value="Ω">Ω</option>
                                <option value="kΩ">kΩ</option>
                                <option value="MΩ">MΩ</option>
                                <option value="ml">ml</option>
                                <option value="l">l</option>
                                <option value="mg">mg</option>
                                <option value="g">g</option>
                                <option value="℃">℃</option>
                                <option value="ppm">ppm</option>
                            </select>
                            <!-- <a-input v-model="item2.min" placeholder="" style="width: 50px"/>
                            -
                            <a-input v-model="item2.max" placeholder="" style="width: 50px"/>
                            <br>
                            <a-input v-model="item2.result" placeholder="" style="width: 300px"/> -->
                        </div>
                    </div>
                    <p/>
                    <a-tag v-if="item.inspectionDescription" color="">설명</a-tag>
                    <!-- <a-divider type = 'vertical' /> -->
                    {{item.inspectionDescription}}
                    <a-divider type = 'horizontal' />
                </div>
            </a-card>
        </div>
        <a-skeleton v-else />
        <div id="image-viewer-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">
            <div id="image-viewer" style="position:absolute; top:20%; left:20%; width:60%; height:60%; background-color:#fff; border:1px solid #ccc;">
                <div id="image-viewer-close" @click="imageViewerClose()" style="position:absolute; top:23px; right:20px; cursor:pointer; font-size:34px; font-weight:bold; -webkit-text-stroke:1px #fff; background-color:#eee;     border:1px solid #ccc;     padding:0 10px; line-height: 1.2; border-radius:4px;">X</div>
                <div id="image-viewer-content" style="height:100%; overflow:auto;"></div>
            </div>
        </div>
        <div id="ng-note-container" style="display:none; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.3);">
            <div id="ng-note" style="position:absolute; top:20%; left:20%; width:60%; height:60%; background-color:#fff; border:1px solid #ccc;">
                <div id="ng-note-close" @click="ngNoteClose()" style="position:absolute; top:10px; right:20px; cursor:pointer; font-size:30px; font-weight:bold; z-index:10;">X</div>
                <a-card title = "NG 기록하기">
                    <a-input v-model="ngTitle" placeholder="제목" />
                    <a-textarea v-model="ngContent" placeholder="NG 내용 기록" rows="17" style="margin-top:20px;"></a-textarea>

                    <div style="margin-top:20px; text-align:center;">
                        <a-button @click="ngNoteClose()" type="default" icon="plus-circle" size = "default" >
                            취소
                        </a-button>
                        <a-button @click="saveNgNote()" type="primary" icon="plus-circle" size = "default" style="margin-left:20px;">
                            완료
                        </a-button>
                    </div>
                </a-card>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
const data = [{title:'제품명'}];
export default {
    name: 'FinishedReportViewer',
    props : ['target', 'flagForDrawer', 'pop', 'showSaveBtn', 'showConfirmBtn'],
    components: {
    },
    data() {
        return {
            flagSpinner : false,
            data,
            fileUploadSeq   : 0,
            fileUploadItem : null,
            ngTitle: '',
            ngContent: '',
            offset: 0,
            offset2: 0,
            uploadFileSeq: []
        };
    },
    mounted() {
        this.setListProductCSInpection();
    },
    watch: {
        target: function(newOne){
            this.target = newOne;

            this.setListProductCSInpection();
        }
    },
    computed : {
        productDescription() {
            let buffer = this.target.clsProduct.productName + " (" + this.target.clsProduct.productModel + ") ";
            for(let x = 0; x < this.target.clsProduct.listProductFunction.length; x ++ ) {
                buffer = buffer + this.target.clsProduct.listProductFunction[x].productFunctionName + ",";
            }
            return buffer;
        },
        productImagePath() {
            return this.$store.state.hostInService + '/upload/image/download/' + this.target.clsProduct.uploadFileSeq;
        },
    },
    methods : {
        setListProductCSInpection() {
            this.target.listProductCSInpection = _.sortBy(this.target.listProductCSInpection, ['inspectionIndex', 'inspectionOffset']);

            // let isEven = false;
            // let oldInspectionTarget = '';
            // _.forEach(this.target.listProductCSInpection, (item) => {
            //     if (!oldInspectionTarget) {
            //         oldInspectionTarget = item.inspectionTarget;
            //     }

            //     if (item.inspectionTarget !== oldInspectionTarget && item.inspectionTarget) {
            //         oldInspectionTarget = item.inspectionTarget;

            //         isEven = !isEven;
            //     }

            //     item.isEven = isEven;
            // });

            let isEven = false;
            let oldInspectionIndex = '';
            _.forEach(this.target.listProductCSInpection, (item) => {
                if (!oldInspectionIndex) {
                    oldInspectionIndex = item.inspectionIndex;
                }

                if (item.inspectionIndex !== oldInspectionIndex) {
                    oldInspectionIndex = item.oldInspectionIndex;

                    isEven = !isEven;
                }

                item.isEven = isEven;
            });
        },
        inspectionImageList(offset) {
            let self = this;

            let html = '';
            let imageSeq = [];
            if (this.target.listProductCSInpection[offset].inspectionImageSeq && this.target.listProductCSInpection[offset].inspectionImageSeq != '0') {
                imageSeq = this.target.listProductCSInpection[offset].inspectionImageSeq.split('||');
            }

            _.forEach(imageSeq, (item, i) => {
                if (i > 4) {    // 최대 5장 표시
                    return;
                }

                let src = this.$store.state.hostInService +'/upload/image/download/' + item;
                html += '<a href = "' + src + '" target="_blank" id="view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '"><img src = "' + src + '" style = "max-width:40px; height:30px;"/></a>';

                ((self) => {
                    // 이미지 보기
                    $('body').off('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item).on('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item, '#view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item , function(e) {
                        e.preventDefault();

                        let html = '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';

                        self.viewInspectionImage2(html);
                    });
                })(self);
            });

            return html;
        },
        inspectionImageList2(offset) {
            let self = this;

            let html = '';
            let imageSeq = [];
            if (this.target.listProductCSInpection[offset].inspectionImage2Seq && this.target.listProductCSInpection[offset].inspectionImage2Seq != '0') {
                imageSeq = this.target.listProductCSInpection[offset].inspectionImage2Seq.split('||');
            }

            _.forEach(imageSeq, (item, i) => {
                if (i > 4) {    // 최대 5장 표시
                    return;
                }

                let src = this.$store.state.hostInService +'/upload/image/download/' + item;
                html += '<a href = "' + src + '" target="_blank" id="view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item + '"><img src = "' + src + '" style = "max-width:40px; height:30px;"/></a>';

                ((self) => {
                    // 이미지 보기
                    $('body').off('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item).on('click.view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item, '#view-image-' + self.target.listProductCSInpection[offset].produtCsInspectionSeq + '-' + item , function(e) {
                        e.preventDefault();

                        let html = '<p style="margin:20px; text-align:center;"><img src = "' + src + '" style = "max-width:900px;"/></p>';

                        self.viewInspectionImage2(html);
                    });
                })(self);
            });

            return html;
        },
        productInspectionPath(offset) {
            return this.$store.state.hostInService +'/upload/image/download/' + this.target.listProductCSInpection[offset].inspectionImageSeq;
        },
        productInspectionPath2(offset) {
            return this.$store.state.hostInService +'/upload/image/download/' + this.target.listProductCSInpection[offset].inspectionImage2Seq;
        },
        handlerForCloseDrawer(target, state) {
            this.$emit('flagForDrawer');

            if (target) {
                let message = '';
                if (target.state !== 'J') {
                    message = target.updated.substr(0, 10) + ' ' + (target.clsProduct.productModelDetail ? target.clsProduct.productModelDetail : target.clsProduct.productModel) + ' 보고서를 승인하시겠습니까?';
                } else {
                    message = target.updated.substr(0, 10) + ' ' + (target.clsProduct.productModelDetail ? target.clsProduct.productModelDetail : target.clsProduct.productModel) + ' 보고서를 승인취소하시겠습니까?';
                }
                if (confirm(message)) {
                    this.$emit('editProductCS', target, state);
                }
            }
        },
        imageUpload(info) {
            this.spinning = info.file.status === 'uploading' ? true : false;
            if(info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
                this.uploadFileSeq.push(info.file.response.data.uploadFileSeq);
                this.fileUploadSeq = this.uploadFileSeq.join('||');
                this.fileUploadItem.inspectionImage2Seq = this.fileUploadSeq;
            } else if(info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        imageUpload2(item) {
            this.fileUploadItem = item;
        },
        viewInspectionImage(src) {
            // window.open('image-viewer.html?src=' + src);
            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-img').src = src;
        },
        viewInspectionImage2(html) {
            document.getElementById('image-viewer-container').style.display = 'block';
            document.getElementById('image-viewer-content').innerHTML = html;
        },
        imageViewerClose() {
            document.getElementById('image-viewer-container').style.display = 'none';
            document.getElementById('image-viewer-content').innerHTML = '';
        },
        viewNgNote(offset, offset2) {
            this.offset = offset;
            this.offset2 = offset2;

            var item = this.target.listProductCSInpection[offset].listProductCSInpectionStandard[offset2];

            this.ngTitle = item.ngTitle;
            this.ngContent = item.ngContent;

            document.getElementById('ng-note-container').style.display = 'block';
        },
        saveNgNote() {
            var item = this.target.listProductCSInpection[this.offset].listProductCSInpectionStandard[this.offset2];

            item.ngTitle = this.ngTitle;
            item.ngContent = this.ngContent;

            this.ngNoteClose();
        },
        ngNoteClose() {
            this.ngTitle = '';
            this.ngContent = '';

            document.getElementById('ng-note-container').style.display = 'none';
        },
        print() {
            // Get HTML to print from element
            const prtHtml = document.getElementById('print').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=1600,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.onload = function() {
                setTimeout(function() {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            };
        },
    }
}
</script>
<style scoped>
    #sheet-table th {
        font-weight: bold;
    }
    
    #sheet-table tr.even {
        background-color: #e8f7ff;
    }
</style>