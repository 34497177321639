/****************************************************************************************
 * File Name    : index.js
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.06.26  	최명호    	Create
****************************************************************************************/
import Vue                      from 'vue'
import VueRouter                from 'vue-router'
import Home                     from '../views/Home.vue'
import Factory                  from '../contents/Factory.vue'
import Accounts                 from '../contents/Accounts.vue'
import Products                 from '../contents/Products.vue'
import Finished                 from '../contents/Finished.vue'
import FinishedReport           from '../contents/FinishedReport.vue'
import Patrol                   from '../contents/Patrol.vue'
import PatrolReport             from '../contents/PatrolReport.vue'
import Patrol4m                 from '../contents/Patrol4m.vue'
import Equipments               from '../contents/Equipments.vue'
import EquipmentControls        from '../contents/EquipmentControls.vue'
import EquipmentControlsAdd     from '../contents/EquipmentControlsAdd.vue'
import EquipmentReport          from '../contents/EquipmentReport.vue'
import Tools                    from '../contents/Tools.vue'
import ToolReport               from '../contents/ToolReport.vue'
import Data                     from '../contents/Data.vue'
import Report                   from '../contents/Report.vue'
import Dashboard                from '../contents/Dashboard.vue'
import DashboardDetailFinished  from '../contents/DashboardDetailFinished.vue'
import DashboardDetailFinished1 from '../contents/DashboardDetailFinished1.vue'
import DashboardDetailFinished2 from '../contents/DashboardDetailFinished2.vue'
import DashboardDetailFinishedNg from '../contents/DashboardDetailFinishedNg.vue'
import DashboardDetailPatrolNg  from '../contents/DashboardDetailPatrolNg.vue'
import DashboardDetailPatrol4m  from '../contents/DashboardDetailPatrol4m.vue'
import FinishedProduct          from '../contents/FinishedProduct.vue'
import PatrolProduct            from '../contents/PatrolProduct.vue'
import SheetToolControls        from '../contents/SheetToolControls.vue'
import SheetEquipmentControls   from '../contents/SheetEquipmentControls.vue'
import SheetFinished            from '../contents/SheetFinished.vue'
import SheetPatrol              from '../contents/SheetPatrol.vue'
import CSVersion                from '../contents/CSVersion.vue'
import TabletMenu               from '../contents/TabletMenu.vue'

Vue.use(VueRouter)

const routes =  [
                {    path: '/',                    name: 'Home',                    component: Home                     },
                {    path: '/factory',             name: 'Factory',                 component: Factory                  },
                {    path: '/account',             name: 'Accounts',                component: Accounts                 },
                {    path: '/product',             name: 'Products',                component: Products                 },
                {    path: '/finished',            name: 'Finished',                component: Finished                 },
                {    path: '/finishedReport',      name: 'FinishedReport',          component: FinishedReport           },
                {    path: '/patrol',              name: 'Patrol',                  component: Patrol                   },
                {    path: '/patrolReport',        name: 'PatrolReport',            component: PatrolReport             },
                {    path: '/patrol4m',            name: 'Patrol4m',                component: Patrol4m                 },
                {    path: '/equipment',           name: 'Equipments',              component: Equipments               },
                {    path: '/equipmentControl',    name: 'EquipmentControls',       component: EquipmentControls        },
                {    path: '/equipmentControlAdd', name: 'EquipmentControlsAdd',    component: EquipmentControlsAdd     },
                {    path: '/equipmentReport',     name: 'EquipmentReport',         component: EquipmentReport          },
                {    path: '/tool',                name: 'Tools',                   component: Tools                    },
                {    path: '/toolReport',         name: 'ToolReport',             component: ToolReport             },
                {    path: '/data',                name: 'Data',                    component: Data                     },
                {    path: '/report',              name: 'Report',                  component: Report                   },
                {    path: '/dashboard',           name: 'Dashboard',               component: Dashboard                },
                {    path: '/dashboardDetailFinished',  name: 'DashboardDetailFinished',  component: DashboardDetailFinished },
                {    path: '/dashboardDetailFinished1',  name: 'DashboardDetailFinished1',  component: DashboardDetailFinished1 },
                {    path: '/dashboardDetailFinished2',  name: 'DashboardDetailFinished2',  component: DashboardDetailFinished2 },
                {    path: '/dashboardDetailFinishedNg',  name: 'DashboardDetailFinishedNg',  component: DashboardDetailFinishedNg },
                {    path: '/dashboardDetailPatrolNg',  name: 'DashboardDetailPatrolNg',  component: DashboardDetailPatrolNg },
                {    path: '/dashboardDetailPatrol4m',  name: 'DashboardDetailPatrol4m',  component: DashboardDetailPatrol4m },
                {    path: '/finishedProduct',     name: 'FinishedProduct',         component: FinishedProduct          },
                {    path: '/patrolProduct',       name: 'PatrolProduct',           component: PatrolProduct            },
                {    path: '/sheetTool',           name: 'SheetToolControls',       component: SheetToolControls        },
                {    path: '/sheetEquipment',      name: 'SheetEquipmentControls',  component: SheetEquipmentControls   },
                {    path: '/sheetFinished',       name: 'SheetFinished',           component: SheetFinished            },
                {    path: '/sheetPatrol',         name: 'SheetPatrol',             component: SheetPatrol              },
                {    path: '/csVersion',           name: 'CSVersion',               component: CSVersion                },
                {    path: '/tabletMenu',          name: 'TabletMenu',              component: TabletMenu               },
                ]

// DESC : 해쉬 모드 히스토리 모드
// DESC : https://happy-coding-day.tistory.com/128
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
export default router
