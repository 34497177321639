/****************************************************************************************
 * File Name    : main.js
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.06.26  	최명호    	Create
****************************************************************************************/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/style.css';
import axios from 'axios'
import moment from "moment"
import VueMomentJS from "vue-momentjs"
import Highcharts from "highcharts"
import Stock from "highcharts/modules/stock"
import HighchartsVue from "highcharts-vue"
Stock(Highcharts)
Vue.use(HighchartsVue)

Vue.prototype.$http = axios
Vue.use(VueMomentJS, moment)
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
