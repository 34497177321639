/****************************************************************************************
 * File Name    : Data.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        
        <div class="menu-container">

            <a-menu class="menu">
                <a-menu-item class="menu-item" key="13"><router-link to="/sheetFinished"><img src="../assets/images/btn-tablet1.jpg" /></router-link></a-menu-item>
                <a-menu-item class="menu-item" key="14"><router-link to="/sheetPatrol"><img src="../assets/images/btn-tablet2.jpg" /></router-link></a-menu-item>
                <a-menu-item class="menu-item" key="15"><router-link to="/sheetEquipment"><img src="../assets/images/btn-tablet3.jpg" /></router-link></a-menu-item>
                <a-menu-item class="menu-item" key="16"><router-link to="/tool"><img src="../assets/images/btn-tablet4.jpg" /></router-link></a-menu-item>
            </a-menu>

        </div>

    </div>
</template>
<script>
export default {
    name: 'TabletMenu',
    components: {
    },
    data() {
        return {
            isDataLoaded : false,
        };
    },
    mounted() {
        
        this.isDataLoaded = true;

        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|samsungbrowser|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        if (isTablet) {
            let x;
            let els1 = document.body.querySelectorAll('.ant-layout-header');
            for (x = 0; x < els1.length; x++) {
                els1[x].style.display = 'none';
            }
            let els2 = document.body.querySelectorAll('.ant-breadcrumb');
            for (x = 0; x < els2.length; x++) {
                els2[x].style.display = 'none';
            }
            let els3 = document.body.querySelectorAll('.ant-divider-horizontal');
            for (x = 0; x < els3.length; x++) {
                els3[x].style.display = 'none';
            }
            let els4 = document.body.querySelectorAll('.desktop-only');
            for (x = 0; x < els4.length; x++) {
                els4[x].style.display = 'none';
            }
            let els5 = document.body.querySelectorAll('.ant-layout-sider');
            for (x = 0; x < els5.length; x++) {
                els5[x].style.display = 'none';
            }
        }
    },
    methods : {

    }
}
</script>
<style scoped>
    .menu-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .menu {
        margin: 100px auto;
        width: 70%;
        border-right: none;
    }

    .menu-item {
        display: inline-block;
        margin-left: 5%;
        margin-right: 5%;
        width: 40%;
        box-sizing: border-box;
        height: auto !important;
    }

    .menu-item a {
        display: block;
        padding: 30px 0 !important;
        text-align: center;
        font-size: 20px !important;
        font-weight: bold;
    }

    .menu-item:last-child {
        margin-bottom: 8px;
    }
</style>