/****************************************************************************************
 * File Name    : Account.vue
 * Function     :
 * Author       : 최명호
 * Tester       :
 * Page         :
 * Target       :
 * Description  :
 * Modification Log
 * ======================================================================================
 * Ver  Date        	Author     	Modification
 * ======================================================================================
   1.0  2021.04.16  	최명호    	Create
****************************************************************************************/
<template>
    <div>
        <a-row :gutter="16">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <PageHeader title = "사용자 등록 현황" />
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box div_action">
                    <a-button type="primary" icon="plus-circle" size = "default" @click="showDrawer">
                        사용자등록
                    </a-button>
                    <!-- <span class = 'icon_button' @click="showDrawer">
                        <a-tag color="#2980B9">
                            <a-icon type="plus-circle" /> 사용자등록
                        </a-tag>

                    </span> -->
                </div>
            </a-col>
        </a-row>
        <a-divider type = 'horizontal' />
        <div class = 'todo'>각 Role/Title 에 따라 Tag 의 색을 달리함 </div>
        <a-card title="" style = 'border:0px solid'>
            <a-card-grid v-for="item in data" v-bind:key="item.key" style="text-align:left;margin:5px 5px 5px 5px" >
                <Account :accountSeq = "item.accountSeq" :name = "item.name" :number = "item.number" :pass = "item.pass" :email = "item.email" :department = "item.department" :title = "item.title" :role = "item.role" :image = "item.image" v-on:editUser="editUser" v-on:deleteUser="deleteUser"/>
            </a-card-grid>
        </a-card>
        <p/>
        <p/>
        <!-- DESC : 사용자 등록 / 수정 / 삭제 -->
        <a-drawer title="사용자 등록/수정" placement="right" width="70vw" :closable="false" :visible="flagForDrawer" :get-container="false" @close="closeDrawer" >
            <AccountForm :accountSeq="selectedAccount.accountSeq" :name="selectedAccount.name" :number="selectedAccount.number" :pass="selectedAccount.pass" :email="selectedAccount.email" :kind="selectedAccount.kind" :profileImageSeq="selectedAccount.profileImageSeq" :image="selectedAccount.image" v-on:addAccount="addAccount" />
        </a-drawer>
    </div>

</template>
<script>
import _            from "lodash";
import PageHeader   from "../components/PageHeader.vue"     ;
import Account      from "../components/Account.vue"        ;
import AccountForm  from "../components/AccountForm.vue"    ;
export default {
    name: 'Accounts',
    components: {
        PageHeader  ,
        Account     ,
        AccountForm
    },
    data() {
        return {
            isDataLoaded : false,
            flagForDrawer: false,
            flagSpinner : false,
            data : [],
            dataArray : [],
            dataOffset : 0,
            selectedAccount : {}
        };
    },
    mounted() {
        this.isDataLoaded = true;

        this.fetchAccountList();
    },
    methods : {
        addAccount() {
            setTimeout(() => {
                location.reload();
            }, 1000);
            
            // this.flagForDrawer = false;
            // this.selectedAccount = {};
            // this.data = [];
            // this.fetchAccountList();
        },
        fetchAccountList() {
            this.dataArray = [];
            this.data = [];
            let urlToFetchAccountList = this.$store.state.hostInService + "/account/list?size=1000";
            this.$http.get(urlToFetchAccountList)
            .then((response) => {
                if(response.status === 200 && response.data.code === 'success') {
                    this.dataArray = response.data.data.content;
                }
                for(let x = 0; x < this.dataArray.length ; x ++) {
                    this.dataArray[x].key = x;
                    this.dataArray[x].role = this.dataArray[x].kind;
                    this.dataArray[x].image = this.$store.state.hostInService + '/upload/image/download/' + this.dataArray[x].profileImageSeq;
                    this.data.push(JSON.parse(JSON.stringify(this.dataArray[x])));
                }
                this.flagSpinner = false;
            }).catch(error => {
                console.log(error);
                this.flagSpinner = false;
            });
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        showDrawer() {
            this.selectedAccount = {
                name: ''
            };
            this.flagForDrawer = true;
        },
        closeDrawer() {
            this.flagForDrawer = false;
            this.selectedAccount = {};
        },
        editUser(accountSeq) {
            let obj = _.find(this.data, {
                accountSeq: accountSeq
            });
            this.selectedAccount = obj;
            this.flagForDrawer = true;
        },
        deleteUser(accountSeq) {
            if (confirm('삭제하시겠습니까?')) {
                let urlForAccount = this.$store.state.hostInService + '/account/delete';
                let generatedObjectToPersist = {
                    accountSeq: accountSeq
                };
                this.$http.put(urlForAccount,generatedObjectToPersist)
                    .then((response) => {
                        if (response.status === 200 && response.data.code === 'success') {
                            this.$message.success('삭제되었습니다');
                            this.fetchAccountList();
                        }  else {
                            this.$message.error('삭제되지 않았습니다(1).');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('삭제되지 않았습니다(2).');
                    });
            }
        }
    }
}
</script>
<style scoped>
    .div_action {
        width : 100%;
        text-align: right;
        padding-bottom: 10px;
        margin-top:15px;
    }
</style>